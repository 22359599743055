import React from "react"
import {  Link } from 'react-router-dom'
import config from 'react-global-configuration';
import {format,addDays,isSameDay,getDay,getMonth, getYear,getDate,subDays} from 'date-fns'
import dayjs from 'dayjs'
import AuthActions from '../actions/AuthActions'
import AuthStore from '../stores/AuthStore'

import BookActions from '../actions/farm/BookActions'
import BookVrmActions from  '../actions/vrm/BookActions'
import BookStore from '../stores/farm/BookStore'
import BookVrmStore from '../stores/vrm/BookStore'
import ErrorStore from '../stores/ErrorStore'
import Header from './parts/Header'
import AlertBox from "./parts/AlertBox"
import styles from "../styles/farm.css"

/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class FarmApp extends React.Component {

  constructor(){
    super()
    this.state = {
      message:null,
      bbq:[],
      alert_box:null,
      vrm_list:[],
      restrant:[]
    }

    const start_date = subDays(new Date(), 2)
    const end_date = addDays(start_date, 180)
    
    this.errorStoreChangeHandler = this.errorStoreChangeHandler.bind(this)
    this.bookStoreChangeHandler = this.bookStoreChangeHandler.bind(this)
    this.bookVrmStoreChangeHandler = this.bookVrmStoreChangeHandler.bind(this)
    this.stopBbqExe = this.stopBbqExe.bind(this)
    config.get("PLANS").map(item => {
      BookVrmActions.getList(item,start_date,end_date)
    })
    config.get("OPTIONS").map(item => {
      BookVrmActions.getOptionList(item,start_date,end_date)
    })
    AuthActions.setAuth("farm")
   
  }
  
  componentDidMount(){
    ErrorStore.addChangeListener(this.errorStoreChangeHandler)
    BookStore.addChangeListener(this.bookStoreChangeHandler)
    BookVrmStore.addChangeListener(this.bookVrmStoreChangeHandler)
    AuthActions.setScopeAuth("farm",BookActions.get)
    //var start_day = dayjs().hour(0).minute(0).second(0).millisecond(0).subtract(1,"day")
    //BookActions.getBbqList(AuthStore.getScopeToken("farm"), start_day.unix(), start_day.add(4,"month").unix())
  }

  componentWillUnmount(){
    BookStore.removeChangeListener(this.bookStoreChangeHandler)
    ErrorStore.removeChangeListener(this.errorStoreChangeHandler)
    BookVrmStore.removeChangeListener(this.bookVrmStoreChangeHandler)
  }

  errorStoreChangeHandler(){
    this.setState({
      message:config.get("ERROR_MESSAGE_1") + ErrorStore.getCode(),
      display_message:true
    })
  }

  bookStoreChangeHandler(){

    this.setState({
      bbq:BookStore.getBbqList(),
      restrant:BookStore.getRestrantList()
    })
  }
  bookVrmStoreChangeHandler(){
    this.setState(
      {vrm_list:BookVrmStore.getList()}
    )
    
  }
  ///Private
  getBbqBook(day){
    var list = []
    for(var i in this.state.bbq){
      const item = this.state.bbq[i]

      if(isSameDay(day,new Date(item.start_date))) {
        list.push(item)
      }
    }
    return list
  }

  getRestrant(day){

    var list = []
    for(var i in this.state.restrant){
      const item = this.state.restrant[i]
      if(isSameDay(day,new Date(item.start_date))) {
        list.push(item)
      }

    }
    return list
  }
  createNewRestrant(){
    window.location = "/farm/restrant/new"
  }
  createNewBbq(){
    window.location = "/farm/bbq/new"
  }
  moveToDate(y,m,d){
    window.location = "/farm/" + y + "-" + m + "-" + d
  }

  stopBbqExe(params){
    var list = []
    var plan = null 
    if(params.type === "l"){
      list = config.get("ROOM_L")
      plan = config.get("PLAN_L")
    }else if(params.type === "d"){
      list = config.get("ROOM_D")
      plan = config.get("PLAN_D")
    }
   
    list.forEach( room_id => {
      BookVrmActions.create(params.date, plan, room_id)
    }) 
    this.setState({
      alert_box:null
    })
  }

  stopBbq(type, date) {
    const param = {type:type, date:date} 
    this.setState({
      alert_box:<AlertBox param={param}  okHandler={this.stopBbqExe} cancelHandler={() => this.setState({alert_box:null})} text="売り止めにしてよろしいですか" cancel="キャンセル" ok="売り止め" />
    })
  }


  render(){

    var today =subDays( new Date() ,1)
    var month = getMonth(today)

    const list =( new Array(180)).fill(0).map((item) => {
      today = addDays(today,1)
      const _today = today
      const bbq = this.getBbqBook(today).map((b) => {
        return <div><a href={"/farm/detail/" + b.id}>{b.plan_name} {b.name}様 {b.person_number > 0 ? b.person_number + "人" : null}</a></div>
      })

      const restrant = this.getRestrant(today).map((b) => {
        return <div><a className="restrant" href={"/farm/detail/" + b.id}>R {b.check_in}〜{b.check_out} {b.name}様 {b.person_number > 0 ? b.person_number + "人" :null}</a></div>
      })

      var month_html = null
      if(month != getMonth(today)){
        month = getMonth(today)
        month_html = <tr className="month"><td colSpan="5">{month + 1}月</td></tr>

      } 
    
      return (
        <tbody>{month_html}
          <tr className={"week_" + getDay(today)}>
            <td><a href={"/farm/" + format(today, "yyyy-MM-dd")} >{getDate(today)}</a> </td>
            <td>{config.get("WEEK_" + getDay(today))}</td><td class="left">{bbq}{restrant}
              <div className="inline-link" onClick={() => this.stopBbq("l", _today)}><i class="fas fa-plus-circle"></i> ランチ売り止めにする</div>
             <div className="inline-link" onClick={() => this.stopBbq("d", _today)}><i class="fas fa-plus-circle"></i> ディナー売り止めにする</div>
            </td>
            <td className="left">{
              BookVrmStore.getByDate(today).map(book => {
             
                return (<div ><div className="list-title">{book.name} {book.person_number}名 {book.days === 1 ? "" : <span className="notice">!</span> }</div>
                  <div>{book.main_content}</div></div>)
              })
              }
              
            </td>
            <td className="left">
              {BookVrmStore.getByDateForOp(today).map(book => {
                return (
                <div>{book.name} {book.unit}名 {book.days === 1 ? "" : <span className="notice">!</span>}
                </div>)
              })
              }

            </td>
          </tr>
        </tbody>)
    })


    return(
      <div>
      <Header />
        <main>
          <div className="innner">
            <div className="main-header">
              <h1>The House Farm 予約表</h1>
              <div className="right-menu">
               
              </div>
            </div>


            <table className="calendar list">
              <thead></thead>
              <tr><th>日付</th><th>曜日</th><th>Farm</th><th>宿泊プラン</th><th>宿泊オプション</th></tr>
                {list}
            </table>
          </div>
        </main>
        {this.state.alert_box}
      </div>
    )
  }
}
