import React from "react"
import config from 'react-global-configuration';

import styles from '../../../styles/site.css'

/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
},
*/

export default class Form extends React.Component {


  constructor(){
    super()
    this.clickCalendar = this.clickCalendar.bind(this)

    this.state = {

    }
  }

  componentDidMount(){

  }

  componentWillUnmount(){

  }

  clickCalendar(e){

  }


  render(){

    //if(!this.props.name) return null

    //const num_select_array = config.get("NUMBER_" + this.props.site)
  


    return(

      <section className="order">
        <h2>お客様情報</h2>
        <div className="site-select">
            <table>
              <thead>
              </thead>
              <tbody>
                <tr><th>代表者氏名</th><td><div><input type="text" name="name" value={this.props.name} onChange={this.props.changeFormHandler} /></div></td></tr>
                <tr><th>電話番号</th><td><div><input type="text" name="tel" value={this.props.tel} onChange={this.props.changeFormHandler} /></div></td></tr>
                <tr><th>住所</th><td><div><input type="text" name="address" value={this.props.address} onChange={this.props.changeFormHandler} /></div></td></tr>
                <tr><th>メール</th><td><div ><input type="email" name="mail" value={this.props.mail} onChange={this.props.changeFormHandler} /></div></td></tr>
                <tr><th>備考</th><td><div ><textarea name="comment"  onChange={this.props.changeFormHandler}>{this.props.comment}</textarea></div></td></tr>

              </tbody>


            </table>

        </div>
      </section>

    )
  }
}
