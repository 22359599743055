import AppDispatcher from "../../dispatcher/AppDispatcher"
import Constants from "../../constants/Constants"
import 'whatwg-fetch'
import AuthActions from '../AuthActions'
import MenuActions from './MenuActions'
import config from 'react-global-configuration';
import cookie from 'react-cookies'


var MediaActions = {

  getList(token){

    fetch(config.get("API_SHOP_CATALOG_PATH") + config.get("API_FILE")  , {
      method: 'GET',
      headers: {
       'Authorization': 'Bearer ' + token
      }
    }).then(function(response) {

      if(response.status == 200){
        return response.json()
      }else if(response.status ===401){
        AuthActions.logout()
        return null
      }else{
        return null
      }
    }).then(function(data) {
      if(data){
        AppDispatcher.dispatch({
          actionType: Constants.FILE_LIST_LOADED,
          list: data.medias,
        })

      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          message: "エラーが発生しました"
        })

      }

    });
  },

  createFile(token, file){
    var formData = new FormData();
    formData.append('file', file);

    fetch(config.get("API_SHOP_CATALOG_PATH") + config.get("API_FILE") , {
      method: 'POST',
      headers: {
       'Authorization': 'Bearer ' + token,
     },
     body: formData
    }).then(function(response) {

      if(response.status == 200){
        return response.json()
      }else if(response.status ===401){
        AuthActions.logout()
        return null
      }else{
        return null
      }
    }).then(function(data) {
      if(data){

        MediaActions.getList(token)


      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          message: "エラーが発生しました"
        })

      }

    });
  },


change(token, product_id, new_id, old_id){


  fetch(config.get("API_SHOP_CATALOG_PATH") + config.get("API_PRODUCT_MEDIA") , {
    method: 'DELETE',
    headers: {
     'Authorization': 'Bearer ' + token,
     'Content-Type': 'application/json'
   },
   body: JSON.stringify({

       media_id: Number(old_id),
       product_id:Number(product_id),
       media_key: "main_image"

  })
  }).then(function(response) {

    if(response.status == 204){
      return true
    }else if(response.status ===401){
      AuthActions.logout()
      return null
    }else{
      return null
    }
  }).then(function(data) {
    if(data){

    MediaActions.create(token, product_id, new_id)

    }else{
      AppDispatcher.dispatch({
        actionType: Constants.API_ERROR,
        message: "エラーが発生しました"
      })

    }

  });
},

create(token, product_id, new_id){


  fetch(config.get("API_SHOP_CATALOG_PATH") + config.get("API_PRODUCT_MEDIA") , {
    method: 'POST',
    headers: {
     'Authorization': 'Bearer ' + token,
     'Content-Type': 'application/json'
   },
   body: JSON.stringify({

       media_id: Number(new_id),
       product_id:Number(product_id),
       media_key: "main_image"

  })
  }).then(function(response) {

    if(response.status == 200){
      return true
    }else if(response.status ===401){
      AuthActions.logout()
      return null
    }else{
      return null
    }
  }).then(function(data) {

    if(data){
      AppDispatcher.dispatch({
        actionType: Constants.MEDIA_UPDATED
      })
      MenuActions.getList(token)

    }else{
      AppDispatcher.dispatch({
        actionType: Constants.API_ERROR,
        message: "エラーが発生しました"
      })

    }

  });
},

updateItem(item){
  AppDispatcher.dispatch({
    actionType: Constants.MENU_UPDATE,
    item: item
  })
}
}

export default MediaActions
