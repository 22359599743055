import { Record, List } from 'immutable'


const Product = Record({
  id:null,
  price:null,
  title:null,
  sub_title: null,
  status:null,
  category:null,
  payment_method:null,
  delivery_method:null,
  medias:[]
});
export default Product;
