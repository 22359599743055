import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'

var AuthActions = {

  setAuth(sc = null){
    var is_auth = false;
    let token = cookie.load('token')
    let refresh_token = cookie.load('refresh_token')
    let scope = cookie.load('scope')

    if(token !== undefined && token !== "" && (sc==null || scope.split(",").indexOf(sc) >= 0)){
      is_auth = true

      AppDispatcher.dispatch({
        actionType: Constants.AUTH, // 誰に
        is_auth: is_auth,
        token: token,
        refresh_token: refresh_token,
        scope:scope
      })

    }else{
      window.location = config.get("LOGIN_URL")
    }

  },

  auth(loginname,password){
    //Login
    var data = new FormData()
    data.append('grant_type', "password")
    data.append('username', loginname)
    data.append('password', password)
    data.append('scope', "admin")
    data.append('client_id', "crew")
    data.append('client_secret', "crew")
    console.log(config.get("API_AUTH_PATH"))
    fetch(config.get("API_AUTH_PATH") + config.get("API_AUTH"), {
      method: 'POST',
      body: data
    }).then(function(response) {
      if(response.status !== 200){
        return false;
      }else{
        return response.json()
      }
    }).then(function(data) {

      var is_auth = false;
      var token = "";
      var refresh_token = "";
      if(data.access_token){
        is_auth = true;
        token = data.access_token
        refresh_token = data.refresh_token
        cookie.save('token', token, { path: '/' })
        cookie.save('refresh_token', refresh_token, { path: '/' })
        AuthActions.getAccount(token)
      }else{

        cookie.remove('token',{ path: '/' })
        cookie.remove('refresh_token',{ path: '/' })
        AppDispatcher.dispatch({
          actionType: Constants.AUTH_NOUSER,
        })
      }
    });
  },

  getAccount(token){

    fetch(config.get("CREW_PATH") + config.get("API_ACCOUNT") + "/me" , {
      method: 'GET',
      headers: {
       'Authorization': 'Bearer ' + token
      },
    }).then(function(response) {

      if(response.status !== 200){
        return null;
      }else{
        return response.json()
      }
    }).then(function(data) {

      if(data){
        cookie.save('scope', data.scope, { path: '/' })
        AuthActions.setAuth()
      }else{

        AppDispatcher.dispatch({
          actionType: Constants.AUTH_NOUSER
        })
      }

    });
  },

  /*
  getToken(scope, url, client_id, client_secret){
    //Login
    var data = new FormData()
    data.append('grant_type', "password")
    data.append('username', loginname)
    data.append('password', password)
    data.append('scope', "admin")
    data.append('client_id', "crew")
    data.append('client_secret', "crew")

    console.log(data)
    console.log(password)
    fetch(config.get("API_AUTH_PATH") + config.get("API_AUTH"), {
      method: 'POST',
      body: data
    }).then(function(response) {
      if(response.status !== 200){
        return false;
      }else{
        return response.json()
      }
    }).then(function(data) {
      console.log(data)
      var is_auth = false;
      var token = "";
      var refresh_token = "";
      if(data.access_token){
        is_auth = true;
        token = data.access_token
        refresh_token = data.refresh_token
        cookie.save('token', token, { path: '/' })
        cookie.save('refresh_token', refresh_token, { path: '/' })
        AuthActions.getAccount(token)
      }else{

        cookie.remove('token',{ path: '/' })
        cookie.remove('refresh_token',{ path: '/' })
        AppDispatcher.dispatch({
          actionType: Constants.AUTH_NOUSER,
        })
      }
    });
  }*/

  setScopeAuth(scope,callback = null, param = null){
    
    let token = cookie.load('token_' + scope)

    if(token !== undefined && token !== ""){

      AppDispatcher.dispatch({
        actionType: Constants.AUTH_SCOPE, // 誰に
        scope: scope,
        token: token
      })
      if(callback) callback(token,param);

    }else{
      console.log(scope)
      AuthActions.getToken(scope,callback,param)
    }
  },


  getToken(scope,callback,param){

    let crew_token = cookie.load('token')
    if(crew_token){
      fetch(config.get("CREW_PATH") + config.get("API_PROJECT") + "/" + scope , {
        method: 'GET',
        headers: {
         'Authorization': 'Bearer ' + crew_token
        },
      }).then(function(response) {

        if(response.status !== 200){
          return null;
        }else{
          return response.json()
        }
      }).then(function(data) {

        if(data){
          var loginname = ""
          var password = ""
          var client_id = ""
          var client_secret = ""
          var scope_out = ""
          for(var i in data.tags){
            const item = data.tags[i]
            if(item.key === "ID") loginname = item.value
            else if(item.key === "PASSWORD") password = item.value
            else if(item.key === "CLIENT_ID") client_id = item.value
            else if(item.key === "CLIENT_SECRET") client_secret = item.value
            else if(item.key === "SCOPE") scope_out = item.value
          }


          //GET OUTER TOKEN
          var form = new FormData()
          form.append('grant_type', "password")
          form.append('username', loginname)
          form.append('password', password)
          form.append('scope', scope_out)
          form.append('client_id', client_id)
          form.append('client_secret', client_secret)



          fetch(config.get("PATH_" + scope) + config.get("AUTH_" + scope) , {
            method: 'POST',
            body: form,
          }).then(function(response) {

            if(response.status !== 200){
              return null;
            }else{
              return response.json()
            }
          }).then(function(data) {

            if(data){
              cookie.save('token_' + scope, data.access_token, { path: '/' })
              AuthActions.setScopeAuth(scope,callback,param)
            }else{

              AppDispatcher.dispatch({
                actionType: Constants.AUTH_NOUSER
              })
            }

          });

        }else{

          AppDispatcher.dispatch({
            actionType: Constants.AUTH_NOUSER
          })
        }

      });
    }else{
      AuthActions.logout()
    }

  },


  logout(){
    cookie.remove('token',{ path: '/' })
    cookie.remove('refresh_token',{ path: '/' })
    cookie.remove('scope',{ path: '/' })
    cookie.remove('token_farm',{ path: '/' })
    cookie.remove('token_takeaway',{ path: '/' })
    cookie.remove('token_vrm',{ path: '/' })
    window.location = config.get("LOGIN_URL")
  },


}

export default AuthActions
