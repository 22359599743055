import React from "react"
import config from 'react-global-configuration';

import Input from '../Input'

import styles from '../../../styles/site.css'
import dayjs from "dayjs"


/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
},
*/

export default class SiteItem extends React.Component {


  constructor(){
    super()
    this.changePersonHandler = this.changePersonHandler.bind(this)

    this.state = {
      person_number:null
    }
  }

  componentDidMount(){


  }

  componentWillUnmount(){

  }

  changePersonHandler(e){
    const target = e.target
    this.props.changeHandler(this.props.item.id, this.props.item.room.id,target.value)
    this.setState({
      person_number:target.value
    })
  }



  render(){
    //console.log(this.props.checked)
    var nums = [<option value={0} checked={this.props.checked && this.props.checked != "" ? "" : "checked"}>選択してください</option>]

    const limit = config.get("NUMBER_" + this.props.item.room.name)
    for(var i = limit[0]; i <=limit[1]; i++){
      nums.push(<option value={i}>{i}人</option>)
    }
    const person_number = this.props.checked ? null : this.state.person_number
    return(
      <div className={"input-box checkbox site-info" + this.props.divclass} key={"item_" + this.props.item.id + "_" + this.props.item.room.id}>
        <input type="checkbox" checked={this.props.checked} id={"item_" + this.props.item.id + "_" + this.props.item.room.id} name="siteplan" value={"item_" + this.props.item.id + "_" + this.props.item.room.id} onChange={()=>{this.props.changeHandler(this.props.item.id, this.props.item.room.id,person_number )}} />
        <label className={"check-parts " + this.props.checked} htmlFor={"item_" + this.props.item.id + "_" + this.props.item.room.id} ></label>{this.props.item.name}&nbsp;&nbsp;&nbsp;
        <div className="input-box select_group"><select name="person_number" onChange={this.changePersonHandler} value={this.state.person_number}>{nums}</select></div>
      </div>


    )
  }
}
