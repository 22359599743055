import AppDispatcher from "../../dispatcher/AppDispatcher"
import Constants from "../../constants/Constants"
import AuthActions from '../AuthActions'
import 'whatwg-fetch'
import config from 'react-global-configuration';



var SmsActions = {

  create(text,phone_number){

    const data = {
    //text:config.get("SMS_CREATED").replace('%unique_id%', unique_id),
    text,
    phone_number:'+81' + phone_number.substr(1,phone_number.length),
    client_id:config.get("SMS_CLIENT")
  };

  fetch(config.get("VRM2_PATH") + config.get("API_SMS") , {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'key': config.get("API2_KEY"),
        'id': config.get("API2_ID")
     },
     body: JSON.stringify(data)
    }).then(function(response) {

      if(response.status == 200){
        return response.json()
      }else if(response.status == 401){
        AuthActions.logout()

        return null
      }else{
        return null
      }
    }).then(function(data) {

      if(data != null ){

        AppDispatcher.dispatch({
          actionType: Constants.SNS_SENT,
        })

      }
    })
  },

  update(text){
    AppDispatcher.dispatch({
      actionType: Constants.SNS_UPDATE,
      text:text
    })

  }


}

export default SmsActions
