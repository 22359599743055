import AppDispatcher from "../../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../../constants/Constants"
import assign from "object-assign"
import Product from "../../vo/Product"


var CHANGE_EVENT = "change" // chenge evnetを定数にする


var _items = []

var setList = (list) => {

  var _list = list.map(
    item => {
      return MenuStore.createItem(item)
    }
  )
  _items = _list
}

var setItem = (item) => {

  for(var i = 0; i < _items.length; i++){
    if(_items[i].id === item.id) _items[i] = MenuStore.createItem(item)
  }
}

var MenuStore = assign({},EventEmitter.prototype,{

  getList(){
    return _items
  },

  getItemFromList(id){
    for(var i = 0; i < _items.length; i++){
      if(_items[i].id === id) return _items[i]
    }
    return null
  },

  createItem(item) {
    
    return new Product({
      id : item.id,
      price : item.price,
      title : item.title,
      sub_title : item.sub_title,
      status:item.status,
      category:item.category,
      payment_method:item.payment_method,
      delivery_method:item.delivery_method,
      medias : item.medias
    })
  },


  emitChange(event_type = CHANGE_EVENT ){ // 何かアクションがあったことを知らせる
    this.emit(event_type)
  },

  addChangeListener(callback, event_type = CHANGE_EVENT ){ // リスナーに追加
    this.on(event_type, callback)
  },

  removeChangeListener(callback, event_type = CHANGE_EVENT ){ // リスナーから削除
    this.removeListener(event_type, callback)
  }

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.MENU_LIST_LOADED:
      setList(action.list);
      MenuStore.emitChange()
      break;
    case Constants.MENU_UPDATE:
      setItem(action.item)
      MenuStore.emitChange()
      break;
    default:
      // no
  }
})
export default MenuStore
