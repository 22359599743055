import React, { useState, useEffect,useCallback } from 'react'
import styles from "../../../styles/takeaway.css"
import AccommodationActions from '../../../actions/scrasy/AccommodationActions'
import AccommodationStore from '../../../stores/scrasy/AccommodationStore'
import AuthStore from '../../../stores/AuthStore'
import Select from 'react-select'
/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/


function Status(props:PropsValue) {


  const [message, setMessage] = useState(AccommodationStore.getStatus())

  const status_message = [
  {value:"", label:"表示なし"},
  {value:"ただいま、混み合っておりまして60分待ちになります。", label:"ただいま、混み合っておりまして60分待ちになります。"},
  {value:"ただいま、混み合っておりまして90分待ちになります。", label:"ただいま、混み合っておりまして90分待ちになります。"},
  {value:"ただいま、混み合っておりまして120分待ちになります。", label:"ただいま、混み合っておりまして120分待ちになります。"},
  {value:"本日は完売となり、オーダーストップとなりました。", label:"本日は完売となり、オーダーストップとなりました。"},
  {value:"営業時間が終了いたしました。", label:"営業時間が終了いたしました。"}
  ]

  const customStyles = {
    singleValue: (provided, state) => ({
      ...provided,
      color: "#000",
    }),
  }

  const stableAccommodationChangeHandler = useCallback(accommodationChangeHandler,[])

  useEffect(() => {
    AccommodationStore.addChangeListener(stableAccommodationChangeHandler,"update")
    return function cleanup() {
      AccommodationStore.removeChangeListener(stableAccommodationChangeHandler,"update")
    };

  },[stableAccommodationChangeHandler]);

  function accommodationChangeHandler(){
    setMessage(AccommodationStore.getStatus())
  }


  function send(){

    AccommodationActions.update(AuthStore.getScopeToken("vrm"),AccommodationStore.getStatus())
    props.closeHandler()
  }

  function changeText(e){
    AccommodationActions.updateStatus(e.target.value)
  }

  function changeHandler(s){

    AccommodationActions.updateStatus(s.value)
  }

  return(
    <div className="overlay">
      <div className="overlay-back" >
      </div>
      <div className="overlay-inner sms-area">

          <input type="text" onChange={changeText}  value={message}/>
          <div className="select-box">
          <Select
                    options={status_message}
                    styles={customStyles}
                    placeholder = "定型文"
                    onChange = {changeHandler}
                    defaultValue = {status_message[0]}
          />
          </div>
          <div className="button"><button className="create" onClick={send} >更新</button></div>
          <div className="close" onClick={props.closeHandler}>[閉じる]</div>
        </div>

    </div>
  )
}

export default Status
