import React from "react"
import {  Link } from 'react-router-dom'
import config from 'react-global-configuration';
import dayjs from 'dayjs'

import AuthActions from '../actions/AuthActions'
import AuthStore from '../stores/AuthStore'

import BookActions from '../actions/farm/BookActions'
import BookStore from '../stores/farm/BookStore'

import ErrorStore from '../stores/ErrorStore'
import Header from './parts/Header'
import styles from "../styles/farm.css"

/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class FarmDayApp extends React.Component {

  constructor({match}){
    super()
    this.errorStoreChangeHandler = this.errorStoreChangeHandler.bind(this)
    this.bookStoreChangeHandler = this.bookStoreChangeHandler.bind(this)

    AuthActions.setScopeAuth("farm")
    this.day = match.params.day

    this.time_select = [{name:"8:30",value:null},
      {name:"9:00",value:null},{name:"9:30",value:null},
      {name:"10:00",value:null},{name:"10:30",value:null},
      {name:"11:00",value:config.get("BBQ_M")},{name:"11:30",value:config.get("BBQ_M")},
      {name:"12:00",value:config.get("BBQ_M")},{name:"12:30",value:config.get("BBQ_M")},
      {name:"13:00",value:config.get("BBQ_M")},{name:"13:30",value:config.get("BBQ_M")},
      {name:"14:00",value:config.get("BBQ_M")},{name:"14:30",value:config.get("BBQ_M")},
      {name:"15:00",value:config.get("BBQ_M")},{name:"15:30",value:null},
      {name:"16:00",value:config.get("BBQ_A")},{name:"16:30",value:config.get("BBQ_A")},
      {name:"17:00",value:config.get("BBQ_A")},{name:"17:30",value:config.get("BBQ_A")},
      {name:"18:00",value:config.get("BBQ_A")},{name:"18:30",value:config.get("BBQ_A")},
      {name:"19:00",value:config.get("BBQ_A")},{name:"19:30",value:config.get("BBQ_A")},
      {name:"20:00",value:null}
      ]


    this.state = {
      message:null,
      bbq:[],
      bbq_deleted:[],
      restrant:[],
      restrant_deleted:[]
    }
  }

  componentDidMount(){
    ErrorStore.addChangeListener(this.errorStoreChangeHandler)
    BookStore.addChangeListener(this.bookStoreChangeHandler)
    const start_date = dayjs(this.day)
    const end_date = dayjs(this.day)
    BookActions.getBbqList(AuthStore.getScopeToken("farm"),start_date,end_date,true)
    BookActions.getRestrantList(AuthStore.getScopeToken("farm"),start_date,end_date,true)
  }

  componentWillUnmount(){
    BookStore.removeChangeListener(this.bookStoreChangeHandler)
    ErrorStore.removeChangeListener(this.errorStoreChangeHandler)
  }

  errorStoreChangeHandler(){

    this.setState({
      message:config.get("ERROR_MESSAGE_1") + ErrorStore.getCode(),
      display_message:true
    })
  }

  bookStoreChangeHandler(){

    this.setState({
      bbq:BookStore.getBbqList(),
      bbq_deleted:BookStore.getBbqDeletedList(),
      restrant:BookStore.getRestrantList(),
      restrant_deleted:BookStore.getRestrantDeletedList()
    })
  }

  getBbqEntry(plan,room){

    for(var i in this.state.bbq){
      const item = this.state.bbq[i]
      if(item.group.plan_id == plan && item.group.room_id == room){
        return item
      }
    }
    return null
  }

  getRestrantEntry(time){
    var result = []
    for(var i in this.state.restrant){
      const item = this.state.restrant[i]

      if(item.group.check_in == time){
        result.push(item)
      }
    }
    return result
  }
  createNewRestrant(){
    window.location = "/farm/restrant/new"
  }
  createNewBbq(){
    window.location = "/farm/bbq/new"
  }

  render(){
    const restrant_no_time = this.getRestrantEntry(null).map((item)=>{
      return <div><a href={"/farm/detail/" + item.id}>{item.group.name}様 {item.group.person_number}名</a></div>
    })

    const list = this.time_select.map((item,i) => {
      const room_a = this.getBbqEntry(item.value, config.get("ROOM_A"))
      const room_b = this.getBbqEntry(item.value, config.get("ROOM_B"))
      const room_c = this.getBbqEntry(item.value, config.get("ROOM_C"))
      const room_d = this.getBbqEntry(item.value, config.get("ROOM_D"))
      const room_e = this.getBbqEntry(item.value, config.get("ROOM_E"))
      const restrant = this.getRestrantEntry(item.name).map((item)=>{
        return <div><a href={"/farm/detail/" + item.id}>{item.group.name}様 {item.group.person_number}名</a></div>
      })

      return <tr><th>{item.name}</th>
        <td className={room_a ? "has_book" : null}>{(room_a && this.time_select[i-1].value == null) ? <a href={"/farm/detail/" + room_a.id}>{room_a.group.name}様 {room_a.group.person_number}名</a> : null}</td>
        <td className={room_b ? "has_book" : null}>{(room_b && this.time_select[i-1].value == null) ? <a href={"/farm/detail/" + room_b.id}>{room_b.group.name}様 {room_b.group.person_number}名</a> : null}</td>
        <td className={room_c ? "has_book" : null}>{(room_c && this.time_select[i-1].value == null) ? <a href={"/farm/detail/" + room_c.id}>{room_c.group.name}様 {room_c.group.person_number}名</a> : null}</td>
        <td className={room_d ? "has_book" : null}>{(room_d && this.time_select[i-1].value == null) ? <a href={"/farm/detail/" + room_d.id}>{room_d.group.name}様 {room_d.group.person_number}名</a> : null}</td>
        <td className={room_e ? "has_book" : null}>{(room_e && this.time_select[i-1].value == null) ? <a href={"/farm/detail/" + room_e.id}>{room_e.group.name}様 {room_e.group.person_number}名</a> : null}</td>
        <td >{restrant}</td>
        </tr>
    })

    const delted_bbq = this.state.bbq_deleted.map((item) => {
      return <tr><td><a href={"/farm/detail/" + item.id}>{item.name}様</a></td><td>{item.person_number}人</td><td>{dayjs(item.created_at).format("YYYY/MM/DD")}</td></tr>
    })
    const delted_restrant = this.state.restrant_deleted.map((item) => {
      return <tr><td><a href={"/farm/detail/" + item.id}>{item.name}様</a></td><td>{item.person_number}人</td><td>{dayjs(item.created_at).format("YYYY/MM/DD")}</td></tr>
    })

    return(
      <div>
      <Header />
        <main>
          <div className="innner">
            <div className="main-header">
              <h1>The House Farm {dayjs(this.day).format("MM月DD日")}予約表</h1>

            </div>


            <table className="calendar list day">
              <thead><tr><th>時間</th><th>A</th><th>B</th><th>C</th><th>D</th><th>E</th><th>Restrant</th></tr></thead>
              <tbody><tr><td>未指定</td><td></td><td></td><td></td><td></td><td></td><td>{restrant_no_time}</td></tr></tbody>
              <tbody>{list}</tbody>
            </table>
            <h2>削除済みリスト</h2>
            <table className="detail list">
              <thead><tr><th>予約名</th><th>人数</th><th>予約日</th></tr></thead>
              <tr><th colSpan="3">BBQ</th></tr>
              <tbody>{delted_bbq}</tbody>
              <tr><th colSpan="3">Restrant</th></tr>
              <tbody>{delted_restrant}</tbody>
            </table>
          </div>
        </main>
      </div>
    )
  }
}
