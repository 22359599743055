import React, { useState, useEffect,useCallback } from 'react'
import {  Link } from 'react-router-dom'
import config from 'react-global-configuration';
import {format} from 'date-fns'


import AuthStore from '../../../stores/AuthStore'
import MediaStore from '../../../stores/scrasy/MediaStore'
import MediaActions from '../../../actions/scrasy/MediaActions'
import styles from "../../../styles/takeaway.css"

/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/


function MediaList(props:PropsValue) {


  const [list, setList] = useState(null)



  const stableMediaChangeHandler = useCallback(mediaChangeHandler, [])
  function mediaChangeHandler(){

    setList(
      MediaStore.getList().map( item=>{

        const img_class = props.item.medias.length > 0 && Number(item.id) === Number(props.item.medias[0].id) ? "selected" : null
        return( <div className="media-list-item" onClick={() => select(item.id)}><img src={item.url} className={img_class} /></div>)
      }
      )
    )
  }




  useEffect(() => {
    MediaStore.addChangeListener(stableMediaChangeHandler)
    MediaActions.getList(AuthStore.getScopeToken("takeaway"))
    return function cleanup() {
      MediaStore.removeChangeListener(stableMediaChangeHandler)
    };

  },[stableMediaChangeHandler]);

  function fileformHandler(f){
    MediaActions.createFile(AuthStore.getScopeToken("takeaway"),f)
  }

  function select(id){
    if(props.item.medias.length > 0){
      MediaActions.change(AuthStore.getScopeToken("takeaway"),props.item.id, id, props.item.medias[0].id)
    }else{
      MediaActions.create(AuthStore.getScopeToken("takeaway"),props.item.id, id)
    }

  }

  return(
    <div className="overlay">
      <div className="overlay-back" onClick={props.closeHandler}>
      </div>
      <div className="overlay-inner media-list">
        {list}
         <div className="media-list-item add"><i className="fas fa-plus-circle"></i><input type="file" onChange = {(e) => fileformHandler(e.target.files[0]) }  /></div>
      </div>
    </div>
  )
}

export default MediaList
