import AppDispatcher from "../../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../../constants/Constants"
import assign from "object-assign"
import { isSameDay, addDays } from 'date-fns'



var CHANGE_EVENT = "change"
var STATUS_UPDATE = "update"

var _item = null
var _status = null

var setItem = (item) => {
  _item = item
  _status = item.description
}
var setStatus = (status) => {
  _status = status
}

var AccommodationStore = assign({},EventEmitter.prototype,{

  getItem(){
    return _item
  },

  getStatus(){
    return _status
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },


})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする
    case Constants.ACCOMMODATION_LOADED:
      setItem(action.item)
      AccommodationStore.emitChange()
    break;
    case Constants.ACCOMMODATION_STATUS_UPDATE:
      setStatus(action.status)
      AccommodationStore.emitChange(STATUS_UPDATE)
    break;

    default:
  }


})
export default AccommodationStore
