import React, { useState, useEffect,useCallback } from 'react'
import styles from "../../../styles/takeaway.css"
import SmsActions from '../../../actions/scrasy/SmsActions'
import SmsStore from '../../../stores/scrasy/SmsStore'

/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/


function SMS(props:PropsValue) {


  const [message, setMessage] = useState("")
  const [btn, setButton] = useState(<div className="button"><button className="create" onClick={send} >SMS送信する</button></div>)

  const stableSmsChangeHandler = useCallback(smsChangeHandler,[])
  const stableSmsCreateChangeHandler = useCallback(smsCreateChangeHandler,[])
  useEffect(() => {
    SmsStore.addChangeListener(stableSmsChangeHandler)
    SmsStore.addChangeListener(stableSmsCreateChangeHandler,"sent")
    return function cleanup() {
      SmsStore.removeChangeListener(stableSmsChangeHandler)
      SmsStore.addChangeListener(stableSmsCreateChangeHandler)
    };

  },[stableSmsChangeHandler,stableSmsCreateChangeHandler]);
  function smsChangeHandler(){
    setMessage(SmsStore.getMessage())
  }
  function smsCreateChangeHandler(){
      setButton(<div className="message">送信しました</div>)
  }


  function changeText(e){
    if(e.target.value.length < 140){
      SmsActions.update(e.target.value)
    }
  }

  function send(){
    console.log(SmsStore.getMessage())
    if(SmsStore.getMessage() != "") {
      setButton(<div className="message">送信中...</div>)
      SmsActions.create(SmsStore.getMessage(),JSON.parse(props.item.customer_info).name)
    }

  }

  return(
    <div className="overlay">
      <div className="overlay-back" >
      </div>
      <div className="overlay-inner sms-area">

          <textarea onChange={changeText}  value={message}/>
          <div className="counter">残り{(140 - message.length)}文字</div>
          {btn}
          <div className="close" onClick={props.closeHandler}>[閉じる]</div>
        </div>

    </div>
  )
}

export default SMS
