import React from "react"
import dayjs from 'dayjs'
//import CalendarActions from '../actions/CalendarActions';
import AuthActions from '../actions/AuthActions';
import BookActions from '../actions/farm/BookActions'
import PlanActions from '../actions/farm/PlanActions'
import MenuActions from '../actions/farm/MenuActions'
import PlanStore from '../stores/farm/PlanStore';
import BookStore from '../stores/farm/BookStore';
import MenuStore from '../stores/farm/MenuStore';
import ErrorStore from '../stores/ErrorStore';
import AuthStore from '../stores/AuthStore';

import Calendar from './parts/calendar/Calendar'
import Site from './parts/farm/Site'
import Menu from './parts/farm/Menu'
import Form from './parts/farm/Form'
import Header from './parts/Header'
import AlertBox from './parts/AlertBox'
import PlanRoom from '../vo/PlanRoom'

import styles from '../styles/calendar.css'
import config from 'react-global-configuration';


export default class BbqApp extends React.Component {

  constructor({match}){
    super()

    AuthActions.setAuth("farm")
    this.handlePreviousMonth = this.handlePreviousMonth.bind(this)
    this.handleNextMonth = this.handleNextMonth.bind(this)
    this.menuChangeHandler = this.menuChangeHandler.bind(this)
    this.planChangeHandler = this.planChangeHandler.bind(this)
    this.bookChangeHandler = this.bookChangeHandler.bind(this)
    this.siteChangeHandler = this.siteChangeHandler.bind(this)
    this.bbqChangeHandler = this.bbqChangeHandler.bind(this)

    this.changeFormHandler = this.changeFormHandler.bind(this)

    this.bookBthHandler = this.bookBthHandler.bind(this)
    this.bookCreatedChangeHandler = this.bookCreatedChangeHandler.bind(this)
    this.calendarClickHandler = this.calendarClickHandler.bind(this)
    this.updateBookedList =this.updateBookedList.bind(this)
    this.errorChangeHandler = this.errorChangeHandler.bind(this)
    this.bookChangeHandler = this.bookChangeHandler.bind(this)
    const today = dayjs()

    this.year = match.params.year ? match.params.year :  today.year()
    this.month = match.params.month ? match.params.month : (today.month() + 1)
    this.currentDate = dayjs(this.year+"-"+(this.month < 10 ? "0" + this.month : this.month )+"-01")
    this.created_counter = 0
    this.state = {
      message:null,
      year:this.year,
      month:this.month,
      plans:[],
      menu:[],
      plan_loaded:false,
      book_loaded:false,
      calendar_data:[],
      selected_date:null,
      selected_site_room:[],
      selected_food:null,
      selected_drink:[],
      pay_ammount:null,
      name:null,
      tel:null,
      address:null,
      mail:null,
      comment:"",
      person_number:null,
      is_loading:false,
      alertBox:null

    }
  }

  componentDidMount(){
    ErrorStore.addChangeListener(this.errorChangeHandler)
    PlanStore.addChangeListener(this.planChangeHandler)
    BookStore.addChangeListener(this.bookCreatedChangeHandler,"created")
    MenuStore.addChangeListener(this.menuChangeHandler)
    BookStore.addChangeListener(this.bookChangeHandler)
    //PlanActions.getList(config.get("ACCOMMODATION_ID_BBQ"))
    //MenuActions.getList(config.get("SHOP_KEY"), config.get("CATEGORY_BBQ") + "," + config.get("CATEGORY_DRINK") )

    //this.updateBookedList(this.year,this.month)
    AuthActions.setScopeAuth("farm")
    AuthActions.setScopeAuth("vrm",this.updateBookedList)
  }

  componentWillUnmount(){
    ErrorStore.removeChangeListener(this.errorChangeHandler)
    PlanStore.removeChangeListener(this.planChangeHandler)
    BookStore.removeChangeListener(this.bookCreatedChangeHandler,"created")
    MenuStore.removeChangeListener(this.menuChangeHandler)
    BookStore.removeChangeListener(this.bookChangeHandler)
    //SiteStore.removeChangeListener(this.SiteStoreChangeHandler)
    //PlanStore.removeChangeListener(this.PlanDetaiChangeHandler,"change_list_date")
    //CalendarStore.removeChangeListener(this.CalendarStoreChangeHandler)
  }

  //StoreHandler
  planChangeHandler(){

    this.setState({
      plans:PlanStore.getList(),
      plan_loaded:true
    })
    this.updateCalendar()
  }

  bookChangeHandler(){
    this.setState({
      book_loaded:true
    })
    this.updateCalendar()
  }
  menuChangeHandler(){
    this.setState({
      menu:MenuStore.getList()
    })
  }

  errorChangeHandler(){
    //console.log(config.get(ErrorStore.getMessage()))
    var code =  ErrorStore.getCode() ?  ErrorStore.getCode() : ""

    this.setState({
      message:config.get(ErrorStore.getMessage()) + " code:" + code,
      is_loading:false
    })
  }

  //Event Handler
  handlePreviousMonth(){
    const prev_date = this.currentDate.subtract(1, 'month')

    window.location = "/farm/bbq/" + prev_date.year() + "/" + (prev_date.month() + 1)
  }

  handleNextMonth(){
    const next_date = this.currentDate.add(1, 'month')
    window.location = "/farm/bbq/" + next_date.year() + "/" + (next_date.month() + 1)

  }

  calendarClickHandler(id){
    console.log(id)
    const m = this.state.month < 10 ? "0"+this.state.month : this.state.month
   /**  AlertBox const d = id < 10 ? "0" + id : id


    this.setState({
      selected_date:dayjs(this.state.year + "-" + m + "-" + d),
      selected_site:null
    })
    */ 
  }

  bookCreatedChangeHandler(){
    this.created_counter ++
    if(this.created_counter >= this.state.selected_site_room.length ){
      window.location = "/farm"
    }
  }

  siteChangeHandler(plan_id,room_id, person_number = null){

    var is_find = false

    for(var i in this.state.selected_site_room){
      const item = this.state.selected_site_room[i]

      if(item.room_id == room_id && item.plan_id == plan_id ){
        if(person_number == null){
          this.state.selected_site_room.splice(i,1)
        }else{
          this.state.selected_site_room[i] = item.set("person_number",Number(person_number))
        }

        is_find = true
        break;
      }
    }

    if(!is_find){
      this.state.selected_site_room.push(new PlanRoom({room_id:room_id,plan_id:plan_id, person_number:person_number}))
    }
    this.setState({
      selected_site_room:this.state.selected_site_room
    })

    console.log(this.state.selected_site_room)
  }




  bbqChangeHandler(id){

    this.setState({
      selected_food:id
    })


  }
  /*drinkChangeHandler(id){
    const index = this.state.selected_drink.indexOf(id)
    if(index > 0){
      this.setState({
        selected_drink:this.state.selected_drink.splice(index,1)
      })
    }else{
      this.state.selected_drink.push(id)
      this.setState({
        selected_drink:this.state.selected_drink
      })
    }
  }*/




  changeFormHandler(e){
    const target = e.target

    switch(target.name){
      case "name" : this.setState({name:target.value}) ;break;
      case "tel" : this.setState({tel:target.value}) ;break;
      case "address" : this.setState({address:target.value}) ;break;
      case "mail" :
        this.setState({mail:target.value,mail_validate:target.validationMessage ? false : true})
        break;
      case "comment" : this.setState({comment:target.value}) ;break;
      case "person_number" : this.setState({person_number:target.value}) ;break;
    }
  }




  bookBthHandler(){

    this.setState({
      is_loading:true,
      message:<i className="fa fa-spinner fa-spin "></i>
    })

    for(var i in this.state.selected_site_room){

      const item = this.state.selected_site_room[i]
      const food = MenuStore.getById(this.state.selected_food)


      const total_price = this.getTotalSitePrice(item)
      var form = {
        accommodation_id:config.get("FARM_BBQ"),
        status:0,
        plan_id:item.plan_id,
        room_id:item.room_id,
        days:1,
        prefecture:0,
        target_date:this.state.selected_date,
        name:this.state.name,
        price:Math.round(total_price  * (1 + config.get("TAX_RATE"))),
        address:this.state.address,
        tel:this.state.tel,
        mail:this.state.mail,
        person_number:item.person_number,
        memo:food.title,
        mini_memo:this.state.comment
      }
      BookActions.create(AuthStore.getScopeToken("farm"),form)
    }

  }
  //Privete Functions
  updateBookedList(){

    this.setState({
      book_loaded:false
    })
    const m = this.month < 10 ? "0"+this.month : this.month
    const start_date = dayjs(this.year+"-"+m+"-01")
    BookActions.getBBQBookedList( start_date.unix() * 1000,start_date.add(1,"month").unix() * 1000)
  }

  updateCalendar(){
    //console.log("updta")
    /*if(!this.state.plan_loaded || !this.state.book_loaded){
      this.setState({
        calendar_data:Array.apply(null, {length: 31}).fill("-")
      })
    }else{
      const m = this.state.month < 10 ? "0"+this.state.month : this.state.month
      const current_date = moment(this.state.year+"-"+m+"-01")

      this.setState({
        calendar_data:Array.apply(null, {length: 31}).map((item,t)=>{
          var available_plans = []
          for(var i in this.state.plans){
            const plan = this.state.plans[i]
            if(current_date.diff(moment(plan.start_at)) >= 0 && current_date.diff(moment(plan.end_at)) <= 0){
              available_plans.push(item)
            }
          }

          //該当する日にプランなし
          if(available_plans.length == 0 ) return "-"

          const book = BookStore.getByDate(current_date.unix() * 1000)
          current_date.add(1,"days")

          if(book && book.booked_number < available_plans.length) return
          else return "-"
        })
      })
    }*/
  }
  getTotalPrice(){
    var total = 0;

    if(!this.state.selected_food  ) return "-"

    //Food
    const food = MenuStore.getById(this.state.selected_food)
    //total = Number(food.price) * Number(this.state.person_number)
    for(var i in this.state.selected_site_room){
      total +=( Number(food.price) * Number(this.state.selected_site_room[i].person_number))
    }
    return total
  }

  getTotalSitePrice(item){
    if(!this.state.selected_food  ) return 0
    //Food
    const food = MenuStore.getById(this.state.selected_food)
    const total =( Number(food.price) * Number(item.person_number))
    return total
  }

  getTotalPersonNum(){

    var total = 0
    for(var i in this.state.selected_site_room){
      total += Number(this.state.selected_site_room[i].person_number)
    }
    return total
  }



render(){

    var target_date = null
    var sitehtml = null



    if(this.state.is_selected && this.state.is_pland_loaded){
     target_date = (new Date(this.state.year, this.state.month - 1, this.state.day)).getTime()
    }

    const site =  this.state.selected_date
      ?
      <Site
        date={this.state.selected_date}
        plans={this.state.plans}
        booked={BookStore.getByDate(this.state.selected_date)}
        selected={this.state.selected_site_room}
        changeHandler = {this.siteChangeHandler}

      />
      : null

    const selected_box = this.state.selected_date
      ? <div className="selected-box"><div className="inner">{this.state.selected_date.format("YYYY年MM月DD日")}  : {this.getTotalPersonNum()}人  {this.getTotalPrice().toLocaleString()}円</div></div>
      : null
    const menu = this.state.selected_site_room.length > 0
      ?
      <Menu selected_food={this.state.selected_food} selected_drink={this.state.selected_drink} menu={this.state.menu}
        bbqChangeHandler={this.bbqChangeHandler}

      />
      : null

    const form = this.state.selected_food  > 0
      ?
      <Form
        site = {this.state.selected_site}
        name = {this.state.name}
        tel = {this.state.tel}
        address = {this.state.address}
        mail = {this.state.mail}
        comment = {this.state.comment}
        changeFormHandler={this.changeFormHandler}
        person_number = { this.person_number}
        />
      : null
      const is_form_filed = this.state.name && this.state.tel && this.state.mail ? true : false
      //&& this.state.name && this.state.tel && this.state.tel && this.state.address  && this.state.mail && this.state.person_number
      const is_btn = this.state.selected_site_room.length > 0  && is_form_filed ? true : false

      const btn = is_btn
          ?
          <section className="btns">
            <button onClick={this.bookBthHandler} className="normal">登録する</button>
          </section>
          : null


    return(
      <div id="usage">
      <Header />
        {selected_box}
        <section className="main-pannel">
          <h1>THE HOUSE FARM 予約</h1>

          <Calendar
            year = {this.state.year}
            month = {this.state.month}
            data = {this.state.calendar_data}
            selected_date={this.state.selected_date}
            handlePreviousMonth = {this.handlePreviousMonth}
            handleNextMonth = {this.handleNextMonth}
            calendarClickHandler = {this.calendarClickHandler}
          />

          {this.state.alert_box}


          <div className="message">{this.state.message}</div>

        </section>

      </div>

    )
  }
}
