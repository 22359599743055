import React from "react"
import {  Link } from 'react-router-dom'
import config from 'react-global-configuration';
import { format } from 'date-fns'


import AuthActions from '../actions/AuthActions'
import AuthStore from '../stores/AuthStore'

import BookActions from '../actions/farm/BookActions'
import BookStore from '../stores/farm/BookStore'

import ErrorStore from '../stores/ErrorStore'
import Header from './parts/Header'
import Input from './parts/Input'
import AlertBox from './parts/AlertBox'
import TopAlertBox from './parts/TopAlertBox'
import styles from "../styles/calendar.css"

/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class FarmDetailApp extends React.Component {

  constructor({match}){
    super()
    this.closeBtnHandler = this.closeBtnHandler.bind(this)
    this.errorStoreChangeHandler = this.errorStoreChangeHandler.bind(this)
    this.bookStoreChangeHandler = this.bookStoreChangeHandler.bind(this)
    this.bookStoreUpdatedHandler = this.bookStoreUpdatedHandler.bind(this)
    this.memoChangeHandler = this.memoChangeHandler.bind(this)
    this.updateBtanHandler = this.updateBtanHandler.bind(this)
    this.deleteBtanHandler = this.deleteBtanHandler.bind(this)
    this.personNumberChangeHandler = this.personNumberChangeHandler.bind(this)
    this.cancelDeleteHandler = this.cancelDeleteHandler.bind(this)
    this.deleteExeHandler = this.deleteExeHandler.bind(this)
    this.id = match.params.id
    this.mode = match.params.mode ? match.params.mode : null

    this.time_select = [{name:"8:30",value:"8:30"},
      {name:"9:00",value:"9:00"},{name:"9:30",value:"9:30"},
      {name:"10:00",value:"10:00"},{name:"10:30",value:"10:30"},
      {name:"11:00",value:"11:00"},{name:"11:30",value:"11:30"},
      {name:"12:00",value:"12:00"},{name:"12:30",value:"12:30"},
      {name:"13:00",value:"13:00"},{name:"13:30",value:"13:30"},
      {name:"14:00",value:"14:00"},{name:"14:30",value:"14:30"},
      {name:"15:00",value:"15:00"},{name:"15:30",value:"15:30"},
      {name:"16:00",value:"16:00"},{name:"16:30",value:"16:30"},
      {name:"17:00",value:"17:00"},{name:"17:30",value:"17:30"},
      {name:"18:00",value:"18:00"},{name:"18:30",value:"18:30"},
      {name:"19:00",value:"19:00"},{name:"19:30",value:"19:30"},
      {name:"20:00",value:"20:00"}
      ]
    AuthActions.setAuth("farm")
      AuthActions.setAuth("vrm")
    this.state = {
      message:null,
      item:null,
      course_text:"",
      memo_text:"",
      delete_id:null
    }
  }

  componentDidMount(){

    ErrorStore.addChangeListener(this.errorStoreChangeHandler)
    BookStore.addChangeListener(this.bookStoreChangeHandler)
    BookStore.addChangeListener(this.bookStoreUpdatedHandler,"created")
    BookStore.addChangeListener(this.bookStoreDeleteddHandler,"deleted")

    AuthActions.setScopeAuth("farm",BookActions.getDetail, this.id)
    AuthActions.setScopeAuth("vrm")
  }

  componentWillUnmount(){
    BookStore.removeChangeListener(this.bookStoreChangeHandler)
    BookStore.removeChangeListener(this.bookStoreUpdatedHandler,"created")
    BookStore.removeChangeListener(this.bookStoreDeleteddHandler,"deleted")
    ErrorStore.removeChangeListener(this.errorStoreChangeHandler)
  }

  closeBtnHandler(){
    this.setState({
      message:null
    })
  }

  errorStoreChangeHandler(){
    this.setState({
      message:config.get("ERROR_MESSAGE_1") + ErrorStore.getCode(),
      display_message:true
    })
  }

  bookStoreChangeHandler(){
    const item = BookStore.getDetail()
    this.setState({
      item:item,
      person_number:item.person_number,
      course_text:item.accommodation_id == config.get("FARM_BBQ")  && item.memo && item.memo.split("/").length > 1 ? item.memo.split("/")[0] : "",
      memo_text:item.mini_memo ? item.mini_memo : item.accommodation_id == config.get("FARM_BBQ") && item.memo && item.memo.split("/").length > 1 ? item.memo.split("/")[1] : item.memo,
    })
  }
  changeValueHandler(name,value){
    BookActions.updateValue(name,value)
  }
  bookStoreUpdatedHandler(){
    this.setState({
      message:config.get("MESSAGE_UPDATED")
    })
  }
  bookStoreDeleteddHandler(){
      window.location = "/farm"
  }
  backBtnHandler(){
    window.location = "/farm"
  }
  personNumberChangeHandler(name,value){
    this.setState({
      person_number:value
    })
    BookActions.updateValue("person_number",value)
  }
  memoChangeHandler(name,value){
    BookActions.updateValue("mini_memo",value)
  }
  deleteBtanHandler(){
    this.setState({
      delete_id:this.id
    })
  }

  cancelDeleteHandler(){
    this.setState({
      delete_id:null
    })
  }
  deleteExeHandler(){
    BookActions.delete(AuthStore.getScopeToken("farm"), this.id)
    this.setState({
      delete_id:null
    })
  }


  updateBtanHandler(){



    var item = this.state.item
    item = item.set("accommodation_id", config.get("FARM_RESTRANT"))
    item.set("memo", this.state.course_text )
    item.set("mini-memo", this.state.course_text )




    if(item.id && item.id > 0){
      BookActions.update(AuthStore.getScopeToken("vrm"),item)
    }else{
      BookActions.create(AuthStore.getScopeToken("vrm"),item)
    }

  }

  render(){

  
    const table = this.state.item
      ? <table className="detail">
        <thead></thead>
        <tbody>
          {this.state.item.accommodation_id == config.get("FARM_RESTRANT") ? <tr><th colSpan="2" className="restrant">レストラン予約 {this.state.item.delete_flg == 1 ? " [削除済み予約]" :null}</th></tr> : null}
          {this.state.item.accommodation_id == config.get("FARM_BBQ") ? <tr><th colSpan="2" className="bbq">BBQ予約 {this.state.item.delete_flg == 1 ? " [削除済み予約]" :null}</th></tr> : null}
          <tr><th>予約名</th><td>{this.mode == null ? this.state.item.name : <Input type="text" value={this.state.item.name} name="name" />}</td></tr>
          <tr><th>予約日</th><td>{format(new Date(this.state.item.start_date), "yyyy年M月d日")}</td></tr>
          <tr><th>人数</th><td ><div className="input-innner">{this.state.person_number}人</div></td></tr>
          <tr><th>連絡先</th><td>{this.state.item.tel}</td></tr>
          <tr><th>メール</th><td>{this.state.item.mail}</td></tr>
          
   
          {this.state.item.accommodation_id == config.get("FARM_BBQ") ? <tr><th>予約時間</th><td>{this.state.item.plan_name}</td></tr> : null}
          {this.state.item.accommodation_id == config.get("FARM_BBQ") ? <tr><th>予約コース</th><td>{this.state.item.memo ? this.state.item.memo.split("/")[0] : null}</td></tr> : null}
          <tr><th>予約発生日</th><td>{format(new Date(this.state.item.created_at), "yyyy年M月d日")}</td></tr>
          <tr>
            <th>備考</th>
            <td><Input type="textarea" value={this.state.item.mini_memo} changeValueHandler={this.memoChangeHandler}/></td>
          </tr>
        </tbody>
        </table>
      : null

    const addclass = this.state.message ? "in" : null
    const delete_alert = this.state.delete_id ? <AlertBox text="削除してよろしいですか。" cancel="キャンセル" ok = "削除" cancelHandler={this.cancelDeleteHandler} okHandler={this.deleteExeHandler}/> :null

    return(
      <div>
      {delete_alert}
      <TopAlertBox text={this.state.message} addclass={addclass} closeBtnHandler={this.closeBtnHandler}/>
      <Header />
        <main>
          <div className="innner">
            <h1>The House Farm 予約詳細</h1>
            {table}
          </div>

          <div className="btn-box">
            <button className="link-btn small dengerous"  onClick = {this.deleteBtanHandler}>削除</button>
            <button className="back-btn midium white"  onClick = {this.backBtnHandler}>戻る</button>
        
          </div>

        </main>
      </div>
    )
  }
}
