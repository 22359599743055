import React from "react"



export default class AlertBox extends React.Component {

  constructor(){
    super()
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }


  handleChange(e){
    const target = e.target

    switch (target.name) {
      case "ok":
        this.props.okHandler(this.props.param)
        break;
      case "cancel":
        this.props.cancelHandler()
        break;
      default:

    }
  }


  render(){
    var btn_html = []

    if(this.props.cancel){
       btn_html.push(<button className="white" onClick={this.handleChange} name="cancel">{this.props.cancel}</button>)
    }

    if(this.props.ok){
       btn_html.push(<button onClick={this.handleChange} name="ok" className="dengerous">{this.props.ok}</button>)
    }

    return(
      <div id="alert-box-wrap" className={this.props.className}>
        <div id="alert-box">
          {this.props.text}
          <div className="button-box">
            {btn_html}
          </div>
        </div>
      </div>
    )
  }
}
