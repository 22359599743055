import AppDispatcher from "../../dispatcher/AppDispatcher"
import Constants from "../../constants/Constants"
import 'whatwg-fetch'
import AuthActions from '../AuthActions'
import config from 'react-global-configuration';
import cookie from 'react-cookies'
import { format, startOfMonth,addDays,getUnixTime} from 'date-fns'


var BookActions = {



  get(token){

    const today = new Date();
    const start_date = startOfMonth(today)
    const end_date = addDays(today, 100)

    BookActions.getBbqList(token,start_date,end_date)
    

  },

  getBbqList(token,start_date,end_date){

    let params = new URLSearchParams();

    params.set("with_consecutive",true )
    params.set('accommodation_id', config.get("FARM_BBQ"));
    params.set("stay_start_date",format(start_date,"yyyy-MM-dd") )
    params.set("stay_end_date",format(end_date,"yyyy-MM-dd") )



    fetch(config.get("VRM2_PATH") + config.get("API_CALENDAR")+ "?" + params.toString() , {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(function(response) {

      if(response.status == 200){
        return response.json()
      }else if(response.status ===401){
        AuthActions.logout()
        return null
      }else{
        return null
      }
    }).then(function(data) {
      
      if(data){
        AppDispatcher.dispatch({
          actionType: Constants.BOOK_LIST_LOADED,
          mode:"bbq",
          list:data.books
        })
      }else{

      }

    });
  },

  /**

  getRestrantList(token,start_date,end_date,deleted=false){
    let params = new URLSearchParams();
    params.set("with_consecutive",true )
    params.set('accommodation_id', config.get("FARM_RESTRANT"));
    params.set("stay_start_date",format(start_date,"yyyy-MM-dd") )
    params.set("stay_end_date",format(end_date,"yyyy-MM-dd") )



    fetch(config.get("VRM2_PATH") + config.get("API_CALENDAR")+ "?" + params.toString() , {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(function(response) {

      if(response.status == 200){
        return response.json()
      }else if(response.status == 401){
        AuthActions.logout()
        return false
      }else{
        return null
      }
    }).then(function(data) {
      
      if(data){
        AppDispatcher.dispatch({
          actionType: Constants.BOOK_LIST_LOADED,
          mode:"restrant",
          list:data.books,

        })
      }else{

      }

    });
  },
   */

  getBBQBookedList( start_date, end_date){
    
    fetch(config.get("VRM2_PATH") + config.get("API_BOOK_LIST"), {
      method: 'GET',
      headers: {
       'Content-Type': 'application/json'
     },

     body: JSON.stringify({
       data:{
         company_id: Number(config.get("COMPANY_ID")),
         accommodation_id:Number(config.get("FARM_BBQ")),
         start_date:Number(start_date),
         end_date:Number(end_date)
       }
     })
    }).then(function(response) {

      if(response.status == 200){
        return response.json()
      }else if(response.status == 401){
        AuthActions.logout()
        return null
      }else{
        return null
      }
    }).then(function(data) {
      if(data != null && data.status == 1){
        AppDispatcher.dispatch({
          actionType: Constants.BOOK_LIST_LOADED,
          mode:"bbq",
          list: data.booked_status
        })
      }
    })
  },

  getDetail(token,id){

    fetch(config.get("VRM2_PATH") + config.get("API_VRM_BOOKS") + "/" + id , {
      method: 'GET',
      headers: {
       'Authorization': 'Bearer ' + token,
      }
    }).then(function(response) {

      if(response.status == 200){
        return response.json()
      }else if(response.status == 401){
        AuthActions.logout()
        return false
      }else{
        return null
      }
    }).then(function(data) {

      if(data){
        console.log(data)
        AppDispatcher.dispatch({
          actionType: Constants.API_BOOKED_DETAIL_LOADED,
          item:data
        })
      }else{

      }

    });
  },


  create(token, form){

    fetch(config.get("VRM2_PATH") + config.get("API_VRM_BOOKS"), {
      method: 'POST',
      headers: {
       'Content-Type': 'application/json',
       'Authorization': 'Bearer ' + token
     },
     body: JSON.stringify({
         "accommodation_id": Number(form.accommodation_id),

         "start_date": format(form.target_date,"yyyy-MM-dd"),
         "days":Number(form.days),
         "name":form.name,
         "price": Number(form.price),
         "address": form.address,
         "tel":form.tel,
         "mail":form.mail,
         "check_in":form.check_in,
         "check_out":form.check_out,

         "mini_memo":form.mini_memo,
         "person_number" : Number(form.person_number),
         "status":Number(form.status),
         "via":Number(form.via)
     })
    }).then(function(response) {
      if(response.status == 200){
        return response.json()
      }else if(response.status == 401){
        console.log("no access permistion")
        AuthActions.logout()
        return false
      }else{
        return null
      }
    }).then(function(data) {
      if(data != null){
        AppDispatcher.dispatch({
          actionType: Constants.BOOK_CREATED
        })
      }else if(data != null && !data){
        AppDispatcher.dispatch({
          actionType: Constants.AUTH_LOGOUT
        })
      }
    })

  },


  update(token, form){

    fetch(config.get("VRM2_PATH") + config.get("API_VRM_BOOKS") + "/" + form.id, {
      method: 'PATCH',
      headers: {
       'Content-Type': 'application/json',
       'Authorization': 'Bearer ' + token
     },
     body: JSON.stringify({
         "accommodation_id": Number(form.accommodation_id),
         "start_date": format(form.target_date,"yyyy-MM-dd"),
         "days":Number(form.days),
         "name":form.name,
         "price": Number(form.price),
         "address": form.address,
         "tel":form.tel,
         "mail":form.mail,
         "check_in":form.check_in,
         "check_out":form.check_out,

         "mini_memo":form.mini_memo,
         "person_number" : Number(form.person_number),
         "status":Number(form.status),
         "via":Number(form.via)
     })
    }).then(function(response) {
      if(response.status == 200){
        return response.json()
      }else if(response.status == 401){
        console.log("no access permistion")
        AuthActions.logout()
        return false
      }else{
        return null
      }
    }).then(function(data) {
      if(data != null){
        AppDispatcher.dispatch({
          actionType: Constants.BOOK_CREATED
        })
      }else if(data != null && !data){
        AppDispatcher.dispatch({
          actionType: Constants.AUTH_LOGOUT
        })
      }
    })

  },



  updateMemo(token, id ,memo){

    fetch(config.get("VRM_PATH") + config.get("API_BOOKED_UPDATE_MEMO"), {
      method: 'POST',
      headers: {
       'Content-Type': 'application/json'
     },
     body: JSON.stringify({
       access_token: token,
       data:{
         "id":Number(id),
         "memo":memo
       }
    })
    }).then(function(response) {
      if(response.status == 200){
        return response.json()
      }else if(response.status == 401){
        AuthActions.logout()
        return false
      }else{
        return null
      }
    }).then(function(data) {
      console.log(data)
      if(data != null && data.status == 1){
        BookActions.getDetail(token,id)
        AppDispatcher.dispatch({
          actionType: Constants.UPDATED_MEMO
        })
      }else if(data != null && !data){
        window.location = config.get("LOGIN_URL")
      }
    })
  },


  delete(token, id){


    fetch(config.get("VRM2_PATH") + config.get("API_VRM_BOOKS") + "/" + id, {
      method: 'DELETE',
      headers: {
       'Content-Type': 'application/json'
     },
     body: JSON.stringify({
       access_token: token,
       data:{
         "id":Number(id),
       }
    })
    }).then(function(response) {
      if(response.status == 200){
        return response.json()
      }else if(response.status == 401){
        return false
      }else{
        return null
      }
    }).then(function(data) {
      if(data != null){
        window.location.href = "/farm"
      }else if(data != null && !data){
        AppDispatcher.dispatch({
          actionType: Constants.AUTH_LOGOUT
        })
      }
    })
  },

  createNew(){
    AppDispatcher.dispatch({
      actionType: Constants.BOOK_CREATE_NEW
    })
  },
  updateValue(name,value){
    AppDispatcher.dispatch({
      actionType: Constants.BOOK_UPDATE,
      name:name,
      value:value
    })
  }
}

export default BookActions
