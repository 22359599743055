import { Record, List } from 'immutable'


const Book = Record({
  id: 0,
  name: "",
  tel:"",
  prefecture:0,
  price:0,
  user_id:0,
  plan_id:0,
  room_id:0,
  check_in:null,
  check_out:null,
  accommodation_id:null,
  mail:"",
  status:0,
  tmp_user_id:null,
  address:"",
  created_at:null,
  person_number:0,
  target_date:null,
  start_date:null,
  days:1,
  via:0,
  short_name:null,
  memo:"",
  mini_memo:"",
  main_content:null,
  paid:[],
  room_name:null,
  plan_name:null,
  payment_limit_date:null,
  user_secret:null,
  id_sha1:null,
  delete_flg:0
});
export default Book;
