import AppDispatcher from "../../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../../constants/Constants"
import assign from "object-assign"


var CHANGE_EVENT = "update" // chenge evnetを定数にする
var UPDATED = "updated" // chenge evnetを定数にする

var _items = []
var _item = null

///BODY
var setList = (list) => {
  _items = list
}


var MenuStore = assign({},EventEmitter.prototype,{


  getList(){
    return _items
  },


  getById(id){
    for(var i in _items){
      if(_items[i].id == id )return _items[i]
    }
    return null
  },


  ///EMIT/ADD/REMOVE
  emitChange(eventname = CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(eventname)
  },

  addChangeListener(callback, eventname = CHANGE_EVENT){ // リスナーに追加
    this.on(eventname, callback)
  },

  removeChangeListener(callback,eventname = CHANGE_EVENT){ // リスナーから削除
    this.removeListener(eventname, callback)
  }

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.PRODUCT_LIST_LOADED:
      setList(action.list);
      MenuStore.emitChange()
    break;
    case Constants.MEDIA_UPDATED:
      MenuStore.emitChange()
    break;

    default:
      // noGET_DETAIL
  }
})
export default MenuStore
