import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import config from 'react-global-configuration';
import configuration from './config.js';
import LoginApp from './components/LoginApp'
import FarmApp from './components/FarmApp'
import FarmDetailApp from './components/FarmDetailApp'
import RestrantEditApp from './components/RestrantEditApp'
import BbqApp from './components/BbqApp'
import FarmDayApp from './components/FarmDayApp'

import TakeAwayApp from './components/TakeAwayApp'
import TakeAwayFullApp from './components/TakeAwayFullApp'
import TakeAwayMenuApp from './components/TakeAwayMenuApp'

import PlanListApp from "./components/PlanListApp"

config.set(configuration);

class App extends React.Component{


render(){
return(
    <div>
      <Switch>
        <Route exact path='/' component={LoginApp} />
        <Route exact path='/farm' component={FarmApp} />
        <Route exact path='/farm/plan' component={PlanListApp} />
        <Route exact path='/farm/:day' component={FarmDayApp} />
        <Route exact path='/farm/detail/:id' component={FarmDetailApp} />
        <Route exact path='/farm/restrant/:id' component={RestrantEditApp} />
        <Route exact path='/farm/bbq/:id' component={BbqApp} />
        <Route exact path='/farm/bbq/:year/:month' component={BbqApp} />
       

        <Route exact path='/takeaway' component={TakeAwayApp} />
        <Route exact path='/takeaway/all' component={TakeAwayFullApp} />
        <Route exact path='/takeaway/menu' component={TakeAwayMenuApp} />

      </Switch>
    </div>
)}}

ReactDOM.render(
  (<BrowserRouter><App /></BrowserRouter>),
  document.getElementById('root')
)
