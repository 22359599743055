import AppDispatcher from "../../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../../constants/Constants"
import assign from "object-assign"
import Book from "../../vo/Book"


var CHANGE_EVENT = "change" // chenge evnetを定数にする


var _items = []
var _named_items = []
var _total_number = 0;

var setList = (list) => {
  _items = list
}
var setTotalNumber = (num) => {
  _total_number = num
}
var setNamedList = (status,list) => {
  var is_find = false

  for(var i = 0; i < _named_items.length; i++ ){
    if(_named_items[i].name === status) {
      _named_items[i] = list
    }
  }
  if(!is_find){
    _named_items.push({name:status, list:list})
  }

}

var clearList = () => {
  _named_items = []
}

var OrderStore = assign({},EventEmitter.prototype,{

  getList(){
    return _items
  },

  getNamedList(status){
    for(var i = 0; i < _named_items.length; i++ ){
      if(_named_items[i].name === status) {
        return _named_items[i].list
      }
    }
    return []
  },


  emitChange(event_type = CHANGE_EVENT ){ // 何かアクションがあったことを知らせる
    this.emit(event_type)
  },

  addChangeListener(callback, event_type = CHANGE_EVENT ){ // リスナーに追加
    this.on(event_type, callback)
  },

  removeChangeListener(callback, event_type = CHANGE_EVENT ){ // リスナーから削除
    this.removeListener(event_type, callback)
  }

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.ORDER_LIST_LOADED:
      if(action.name){
        setNamedList(action.name, action.list);
      }else{
        setList(action.list);
      }
      setTotalNumber(action.total)
      OrderStore.emitChange()
      break;
    case Constants.ORDER_UPDATED:
      clearList()
    break;
    default:
      // no
  }
})
export default OrderStore
