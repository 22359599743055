import AppDispatcher from "../../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../../constants/Constants"
import assign from "object-assign"
import Book from "../../vo/Book"
import dayjs from 'dayjs'

var CHANGE_EVENT = "change" // chenge evnetを定数にする
var UPDATED = "updated"
var CREATED = "created"
var DELETED = "deleted"

var _bbq = []
var _bbq_deleted = []
var _restrant = []
var _restrant_deleted = []
var _item = null

var setList = (mode, list ,deleted_list) => {

  if(mode === "bbq"){
    _bbq = list
    _bbq_deleted = deleted_list
  }else if(mode === "restrant"){
    _restrant = list
    _restrant_deleted = deleted_list
  }
}

var setItem = (item) => {

  _item = new Book({
    id: item.id,
    name:item.name,
    tel:item.tel,
    prefecture:0,
    price:item.price,
    user_id:item.user_id,
    plan_id:item.plan_id,
    room_id:item.room_id,
    check_in:item.check_in,
    check_out:item.check_out,
    accommodation_id:item.accommodation_id,
    mail:item.mail,
    status:item.status,
    tmp_user_id:item.tmp_user_id,
    address:item.address,
    created_at:item.created_at,
    person_number:item.person_number,
    target_date:item.target_date,
    start_date:item.start_date,
    days:1,
    paid:item.paid,
    via:0,
    short_name:null,
    memo:item.memo,
    mini_memo:item.mini_memo,
    room_name:item.room_name,
    plan_name:item.plan_name,
    main_content:item.main_content,
    delete_flg:item.delete_flg
  })
}

var createNew = () => {
  _item = new Book()
}

var update = (name,value) => {
  _item = _item.set(name,value)
}
var BookStore = assign({},EventEmitter.prototype,{

  getDetail(){
    return _item
  },

  getBbqList(){
    return _bbq
  },

  getRestrantList(){
    return _restrant
  },

  getBbqDeletedList(){
    return _bbq_deleted
  },

  getRestrantDeletedList(){
    return _restrant_deleted
  },

  getByDate(date){

    for(var i in _bbq){
      if(dayjs(_bbq[i].target_date).diff(dayjs(date),"day") === 0) return _bbq[i]
    }
    return null
  },

  emitChange(event_type = CHANGE_EVENT ){ // 何かアクションがあったことを知らせる
    this.emit(event_type)
  },

  addChangeListener(callback, event_type = CHANGE_EVENT ){ // リスナーに追加
    this.on(event_type, callback)
  },

  removeChangeListener(callback, event_type = CHANGE_EVENT ){ // リスナーから削除
    this.removeListener(event_type, callback)
  }

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.BOOK_LIST_LOADED:
      setList(action.mode, action.list, action.deleted_list);
      BookStore.emitChange()
      break;
    case Constants.API_BOOKED_DETAIL_LOADED:
      setItem(action.item);
      BookStore.emitChange()
      break;
    case Constants.UPDATED_MEMO:
      BookStore.emitChange(UPDATED)
      break;
    case Constants.BOOK_CREATE_NEW:
      createNew()
      BookStore.emitChange()
      break
    case Constants.BOOK_UPDATE:
      update(action.name,action.value)
      BookStore.emitChange()
      break
    case Constants.BOOK_CREATED:
      BookStore.emitChange(CREATED)
      break
    case Constants.BOOK_DELETED:
      BookStore.emitChange(DELETED)
      break
    default:
      // no
  }
})
export default BookStore
