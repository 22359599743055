import AppDispatcher from "../../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../../constants/Constants"
import assign from "object-assign"
import Product from "../../vo/Product"


var CHANGE_EVENT = "change" // chenge evnetを定数にする
var SENT = "sent"

var _message = ""

var setMessage = (item) => {
  _message = item
}

var SmsStore = assign({},EventEmitter.prototype,{

  getMessage(){
    return _message
  },

  emitChange(event_type = CHANGE_EVENT ){ // 何かアクションがあったことを知らせる
    this.emit(event_type)
  },

  addChangeListener(callback, event_type = CHANGE_EVENT ){ // リスナーに追加
    this.on(event_type, callback)
  },

  removeChangeListener(callback, event_type = CHANGE_EVENT ){ // リスナーから削除
    this.removeListener(event_type, callback)
  }

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.SNS_SENT:

      SmsStore.emitChange(SENT)
      break;
      case Constants.SNS_UPDATE:
        setMessage(action.text)
        SmsStore.emitChange()
        break;
    default:
      // no
  }
})
export default SmsStore
