import AppDispatcher from "../../dispatcher/AppDispatcher"
import Constants from "../../constants/Constants"
import 'whatwg-fetch'
import config from 'react-global-configuration';
import { format,} from 'date-fns'


var BookActions = {

  getList(item, start_date, end_date){
    let params = new URLSearchParams();

    params.set("company_id",config.get("COMPANY_TH_ID") )
    params.set('accommodation_id', item.accommodation_id);
    params.set("stay_start_date",format(start_date,"yyyy-MM-dd") )
    params.set("stay_end_date",format(end_date,"yyyy-MM-dd") )
    params.set("plan_id",item.plan_id )

    fetch(config.get("VRM2_PATH") + config.get("API_BOOKS")  + "/?" + params.toString(), {
      method: 'GET',
      headers: {
       
     }
    }).then(function(response) {

      if(response.status == 200){
        return response.json()
      }else{
        return null
      }
    }).then(function(data) {

      if(data != null){
        AppDispatcher.dispatch({
          actionType: Constants.VRM_BOOK_LIST_LOADED,
          list:data.books,
          name:item.name
        })
      }
    })
  },

  getOptionList(item, start_date, end_date){
    let params = new URLSearchParams();

    params.set("company_id",config.get("COMPANY_TH_ID") )
    params.set('accommodation_id', item.accommodation_id);
    params.set("stay_start_date",format(start_date,"yyyy-MM-dd") )
    params.set("stay_end_date",format(end_date,"yyyy-MM-dd") )
    params.set("option_id",item.option_id )

    fetch(config.get("VRM2_PATH") + config.get("API_BOOKS")  + "/?" + params.toString(), {
      method: 'GET',
      headers: {
       
     }
    }).then(function(response) {

      if(response.status == 200){
        return response.json()
      }else{
        return null
      }
    }).then(function(data) {

      if(data != null){
        AppDispatcher.dispatch({
          actionType: Constants.VRM_BOOK_OPT_LIST_LOADED,
          list:data.books,
          name:item.name
        })
      }
    })
  },
  
  create(start_date, plan_id, room_id){

    fetch(config.get("VRM2_PATH") + config.get("API_BOOKS"), {
      method: 'POST',
      headers: {
       'Content-Type': 'application/json',
       'key': config.get("API2_KEY"),
       'id': config.get("API2_ID")
     },
     body: JSON.stringify({
         "accommodation_id": config.get("ACCOMMODATION_ID_BBQ"),
         "target_date": format(start_date,"yyyy-MM-dd"),
         "days":1,
         "person_number":1,
         "name":"売り止め",
         "plan_id": plan_id,
         "room_id": room_id
     })
    }).then(function(response) {
      if(response.status == 200){
        return response.json()
      
      }else{
        return null
      }
    }).then(function(data) {
      if(data != null){
        console.log(data)
      }
    })

  },

}

export default BookActions
