import AppDispatcher from "../../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../../constants/Constants"
import assign from "object-assign"
import { isSameDay,} from 'date-fns'
import Common from "../../utiles/Common"

var CHANGE_EVENT = "change" // chenge evnetを定数にする


var _list = []
var _oplist = []

var addist = (list,name) => {

  const new_list = list.map(
    item => {
      return {
        person_number : item.person_number,
        days : item.days,
        name : name + item.plan_name,
        start_date : new Date(item.start_date),
        main_content:  Common.nl2br(item.main_content)
      }
    }
  )
  _list = _list.concat(new_list)
}
var addOpist = (list,name) => {

  const new_list = list.map(
    item => {
      return {
        unit : item.unit,
        days : item.days,
        name : name  +item.name,
        start_date : new Date(item.start_date),
        main_content: Common.nl2br(item.main_content)
      }
    }
  )
  _oplist = _oplist.concat(new_list)
}

/**
.map(item => 
  return ({
  , days : item.days,
  name : item.name,
  target_date : item.start_date
  })
)
 */
var BookStore = assign({},EventEmitter.prototype,{

  getList(){
    return _list
  },

  getByDate(date){
    return _list.filter(item => isSameDay(item.start_date,date))
  },

  getByDateForOp(date){
    return _oplist.filter(item => isSameDay(item.start_date,date))
  },

  emitChange(event_type = CHANGE_EVENT ){ // 何かアクションがあったことを知らせる
    this.emit(event_type)
  },

  addChangeListener(callback, event_type = CHANGE_EVENT ){ // リスナーに追加
    this.on(event_type, callback)
  },

  removeChangeListener(callback, event_type = CHANGE_EVENT ){ // リスナーから削除
    this.removeListener(event_type, callback)
  }

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.VRM_BOOK_LIST_LOADED:
      addist(action.list, action.name);
      BookStore.emitChange()
      break;
    
    case Constants.VRM_BOOK_OPT_LIST_LOADED:
       addOpist(action.list, action.name);
       BookStore.emitChange()
      break;
    
    default:
      // no
  }
})
export default BookStore
