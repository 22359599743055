import React from "react"
import config from 'react-global-configuration';
import dayjs from 'dayjs'

import PlanStore from '../../../stores/farm/PlanStore'
import BookStore from '../../../stores/farm/BookStore'
/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
},
*/

export default class Calendar extends React.Component {


  constructor(){
    super()
    this.clickCalendar = this.clickCalendar.bind(this)
    this.calendarClickHandler = this.calendarClickHandler.bind(this)
    this.state = {
      year:null,
      month:null
    }
  }

  componentDidMount(){


    this.setState({

    })
  }

  componentWillUnmount(){

  }

  clickCalendar(e){

  }
  calendarClickHandler(d){
    this.props.calendarClickHandler(d)
  }
  render(){
    const today = dayjs()
    //Header
    const head_html = Array.apply(null, {length: 7}).map((item,i)=>{
      return <th className={"week_" + i} key={"head_"+i}>{config.get("WEEK_" + i)}</th>
    })
    const m = this.props.month < 10 ? "0"+this.props.month : this.props.month

    var start_dt = dayjs(this.props.year + "-" + (m) + "-01");

    const end_dt = dayjs(this.props.year + "-" + (m) + "-01").endOf('month')

    const numOfWeeks = Math.ceil(end_dt.diff(start_dt, "days") / 7);

    const tbodyhtml = Array.apply(null, {length: numOfWeeks}).map((item,i) =>
      <tr  key={"tr_" + i}>
        {[0,1,2,3,4,5,6].map((date) =>{
            var is_available = false
            if((i == 0 && date < start_dt.day()) || (start_dt.month()+1) != this.props.month){
              //今月以外
              return <td key={"blank_" + i + "_" + date}></td>
            }else{
              //可能なプラン

              const book = BookStore.getByDate(start_dt)
              console.log(book)
              const d = start_dt.date()
              var divClass = is_available ? "available" : ""
              var input_data = book ? book.name : ""

              if(this.props.selected_date && start_dt.isSame(this.props.selected_date,'day')){
                divClass = "selected"
              }

              const _html = (
                <td className={divClass} key={"date_" + start_dt.date()} onClick={() => {this.calendarClickHandler(d)}}>
                  <div className="date" >{start_dt.date()}</div>
                  <div className="roomnum">
                    <div>{input_data}</div>
                  </div>
                </td>
              )

              start_dt = start_dt.add(1,"day")
              return _html
            }
          })
        }
      </tr>)




    return(
      <div>
        <div className="month-selector">
          <div className="navigator" onClick={this.props.handlePreviousMonth}><i className="fa fa-chevron-left"></i>&nbsp;前の月</div>
          <div>{this.props.year}年{this.props.month}月</div>
          <div className="navigator" onClick={this.props.handleNextMonth}>次の月&nbsp;<i className="fa fa-chevron-right"></i></div>
        </div>


        <table className="calendar full">
          <thead><tr>{head_html}</tr></thead>
          <tbody>
            {tbodyhtml}
          </tbody>
        </table>
      </div>
    )
  }
}
