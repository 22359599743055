import AppDispatcher from "../../dispatcher/AppDispatcher"
import Constants from "../../constants/Constants"
import 'whatwg-fetch'
import AuthActions from '../AuthActions'
import config from 'react-global-configuration';
import cookie from 'react-cookies'


var AccommodationActions = {

  getDetail(){

      fetch(config.get("VRM2_PATH") +  config.get("API_ACCOMMODATION_USER") + "/" + config.get("ACCOMMODATION_ID")  , {
      method: 'GET',
      headers: {

      }
    }).then(function(response) {

      if(response.status == 200){
        return response.json()
      }else if(response.status ===401){
        AuthActions.logout()
        return null
      }else{
        return null
      }
    }).then(function(data) {

      if(data){
        AppDispatcher.dispatch({
          actionType: Constants.ACCOMMODATION_LOADED,
          item: data,
        })

      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          message: "エラーが発生しました"
        })

      }

    });
  },

  update(token, status){
    console.log(status)
    fetch(config.get("VRM2_PATH") + config.get("API_ACCOMMODATION") + "/" + config.get("ACCOMMODATION_ID") , {
      method: 'PATCH',
      headers: {
       'Authorization': 'Bearer ' + token,
       'Content-Type': 'application/json'
     },
     body: JSON.stringify({
       description:status === "" ? null : status,
     })
    }).then(function(response) {

      if(response.status == 200){
        return response.json()
      }else if(response.status ===401){
        AuthActions.logout()
        return null
      }else{
        return null
      }
    }).then(function(data) {
      if(data){

        AppDispatcher.dispatch({
          actionType: Constants.ACCOMMODATION_LOADED,
          item: data,
        })


      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          message: "エラーが発生しました"
        })

      }

    });
  },
  updateStatus(status){
    AppDispatcher.dispatch({
      actionType: Constants.ACCOMMODATION_STATUS_UPDATE,
      status: status
    })
  }
}

export default AccommodationActions
