import AppDispatcher from "../../dispatcher/AppDispatcher"
import Constants from "../../constants/Constants"
import 'whatwg-fetch'
import AuthActions from '../AuthActions'
import config from 'react-global-configuration';
import cookie from 'react-cookies'
import {format,subDays} from 'date-fns'
import SmsActions from './SmsActions'
import SlackActions from '../SlackActions'

var OrderActions = {

  getList(token, status = null){

    let params = new URLSearchParams();

    if(status){
      params.set('status', status);
    }

    if(status === null){
      params.set('start_date', format(subDays(new Date(),3),"yyyy-MM-dd"));
    }

    if(status === null){
      params.set('end_date', format(new Date(),"yyyy-MM-dd"));
    }

    fetch(config.get("API_SHOP_CART_PATH") + config.get("API_CART_ORDER")  + "?" + params.toString(), {
      method: 'GET',
      headers: {
       'Authorization': 'Bearer ' + token
      }
    }).then(function(response) {

      if(response.status == 200){
        return response.json()
      }else if(response.status ===401){
        AuthActions.logout()
        return null
      }
      throw new Error('Network response was not ok.');
    }).then(function(data) {

      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.ORDER_LIST_LOADED,
          list: data.trades,
          total:data.total,
          name:status
        })
        //SlackActions.sendMessage("OK")
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          message: "エラーが発生しました"
        })
        SlackActions.sendMessage("ERROR---:" + data.error)
      }

    }).catch(function(error) {
        SlackActions.sendMessage("ERROR---:" + error)
    });
  },

  update(token, item ,num, full = false ,status = null){

    fetch(config.get("API_SHOP_CART_PATH") + config.get("API_CART_ORDER")  , {
      method: 'PATCH',
      headers: {
       'Authorization': 'Bearer ' + token,
       'Content-Type': 'application/json'
     },
     body: JSON.stringify({
      trade_id:item.id,
      status:status ? Number(status) : Number(num)
     })
    }).then(function(response) {

      if(response.status == 200){
        return response.json()
      }else if(response.status ===401){
        AuthActions.logout()
        return null
      }
    }).then(function(data) {


      if(data){
        AppDispatcher.dispatch({
          actionType: Constants.ORDER_UPDATED,
          message: "エラーが発生しました"
        })
        if(!full){
          OrderActions.getList(token,1)
          OrderActions.getList(token,2)
          OrderActions.getList(token,3)
        }else{
          OrderActions.getList(token)
        }

        if(item.status === 2 && num === 3){
          //console.log("send smes")
          SmsActions.create(config.get("SMS_CREATED"),JSON.parse(item.customer_info).name)
        }
      }else{
        AuthActions.logout()
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          message: "エラーが発生しました"
        })
      }
    });
  }

}

export default OrderActions
