import React from "react"
import {  Link } from 'react-router-dom'
import config from 'react-global-configuration';
import {getUnixTime} from 'date-fns'

import AuthActions from '../actions/AuthActions'
import AuthStore from '../stores/AuthStore'

import BookActions from '../actions/farm/BookActions'
import BookStore from '../stores/farm/BookStore'

import ErrorStore from '../stores/ErrorStore'
import Header from './parts/Header'
import Input from './parts/Input'
import TopAlertBox from './parts/TopAlertBox'
import styles from "../styles/calendar.css"
import DatePicker,{registerLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import ja from 'date-fns/locale/ja'
/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class RestrantEditApp extends React.Component {

  constructor({match}){
    super()
      registerLocale('ja', ja)
    this.closeBtnHandler = this.closeBtnHandler.bind(this)
    this.errorStoreChangeHandler = this.errorStoreChangeHandler.bind(this)
    this.bookStoreChangeHandler = this.bookStoreChangeHandler.bind(this)
    this.bookStoreUpdatedHandler = this.bookStoreUpdatedHandler.bind(this)
    this.updateBtanHandler = this.updateBtanHandler.bind(this)
    this.id = match.params.id
    this.time_select = [{name:"8:30",value:"8:30"},
      {name:"9:00",value:"9:00"},{name:"9:30",value:"9:30"},
      {name:"10:00",value:"10:00"},{name:"10:30",value:"10:30"},
      {name:"11:00",value:"11:00"},{name:"11:30",value:"11:30"},
      {name:"12:00",value:"12:00"},{name:"12:30",value:"12:30"},
      {name:"13:00",value:"13:00"},{name:"13:30",value:"13:30"},
      {name:"14:00",value:"14:00"},{name:"14:30",value:"14:30"},
      {name:"15:00",value:"15:00"},{name:"15:30",value:"15:30"},
      {name:"16:00",value:"16:00"},{name:"16:30",value:"16:30"},
      {name:"17:00",value:"17:00"},{name:"17:30",value:"17:30"},
      {name:"18:00",value:"18:00"},{name:"18:30",value:"18:30"},
      {name:"19:00",value:"19:00"},{name:"19:30",value:"19:30"},
      {name:"20:00",value:"20:00"}
      ]
    AuthActions.setAuth("farm")
      AuthActions.setAuth("vrm")
    this.state = {
      message:null,
      item:null,
      course_text:"",
      memo_text:""

    }
  }

  componentDidMount(){

    ErrorStore.addChangeListener(this.errorStoreChangeHandler)
    BookStore.addChangeListener(this.bookStoreChangeHandler)
    BookStore.addChangeListener(this.bookStoreUpdatedHandler,"created")
    if(this.id == "new"){
        BookActions.createNew()
        AuthActions.setScopeAuth("farm")
        AuthActions.setScopeAuth("vrm")
    }else{
        AuthActions.setScopeAuth("farm",BookActions.getDetail, this.id)
        AuthActions.setScopeAuth("vrm")
    }


  }

  componentWillUnmount(){
    BookStore.removeChangeListener(this.bookStoreChangeHandler)
    BookStore.removeChangeListener(this.bookStoreUpdatedHandler,"created")
    ErrorStore.removeChangeListener(this.errorStoreChangeHandler)
  }

  closeBtnHandler(){
    this.setState({
      message:null
    })
  }

  errorStoreChangeHandler(){
    this.setState({
      message:config.get("ERROR_MESSAGE_1") + ErrorStore.getCode(),
      display_message:true
    })
  }

  bookStoreChangeHandler(){
    const item = BookStore.getDetail()
    this.setState({
      item:item
    })
  }

  changeValueHandler(name,value){
    BookActions.updateValue(name,value)
  }

  bookStoreUpdatedHandler(){
    /*this.setState({
      message:config.get("MESSAGE_UPDATED")
    })*/
    window.location = "/farm"
  }

  backBtnHandler(){
    window.location = "/farm"
  }

  setDate(e){
    console.log(e)
    BookActions.updateValue("target_date",e)
  }


  updateBtanHandler(){

    var item = this.state.item
    item = item.set("accommodation_id", config.get("FARM_RESTRANT"))
    if(item.id && item.id > 0){
      BookActions.update(AuthStore.getScopeToken("vrm"),item)
    }else{
      BookActions.create(AuthStore.getScopeToken("vrm"),item)
    }

  }

  render(){
    //console.log(this.state.item)
    var paid_price = 0;
    if(this.state.item){
      for(var i in this.state.item.paid){
        paid_price += this.state.item.paid[i].amount
      }
    }


    const table = this.state.item
      ? <table className="detail">
        <thead></thead>
        <tbody>
          <tr><th colSpan="2" className="restrant">レストラン予約</th></tr>

          <tr><th>予約名</th><td><Input type="text" value={this.state.item ? this.state.item.name : null} name="name" changeValueHandler={this.changeValueHandler}/></td></tr>
          <tr><th>予約日</th><td><DatePicker  showPopperArrow={false} selected={this.state.item.target_date} locale="ja" placeholderText="予約日" dateFormat="yyyy/MM/dd" onChange={(e) => this.setDate(e)} /></td></tr>
          <tr><th>人数</th><td><Input type="number" value={this.state.item ? this.state.item.person_number : null} name="person_number" changeValueHandler={this.changeValueHandler}/></td></tr>
          <tr><th>連絡先</th><td><Input type="text" value={this.state.item ? this.state.item.tel : null} name="tel" changeValueHandler={this.changeValueHandler}/></td></tr>
          <tr><th>予約時間</th><td>
            <div className="input-innner">
              <Input type="select" value={this.state.item ? this.state.item.check_in : null} name="check_in" values={this.time_select} changeValueHandler={this.changeValueHandler}/>
              <span> 〜 </span>
              <Input type="select" values={this.time_select}  value={this.state.item ? this.state.item.check_out : null} name="check_out" changeValueHandler={this.changeValueHandler}/>
            </div>
          </td></tr>


          <tr>
            <th>備考</th>
            <td><Input type="textarea" name="mini_memo" value={this.state.item.mini_memo} changeValueHandler={this.changeValueHandler}/></td>
          </tr>
        </tbody>
        </table>
      : null

    const addclass = this.state.message ? "in" : null
    return(
      <div>
      <TopAlertBox text={this.state.message} addclass={addclass} closeBtnHandler={this.closeBtnHandler}/>
      <Header />
        <main>
          <div className="innner">
          <div className="main-header">
            <h1>The House Farm 予約</h1>

          </div>
            {table}
          </div>

          <div className="btn-box">
            <button className="back-btn midium white"  onClick = {this.backBtnHandler}>戻る</button>
            <button className="link-btn midium "  onClick = {this.updateBtanHandler}>保存</button>
          </div>

        </main>
      </div>
    )
  }
}
