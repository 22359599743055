import AppDispatcher from "../../dispatcher/AppDispatcher"
import Constants from "../../constants/Constants"
import AuthActions from '../AuthActions'
import 'whatwg-fetch'
import config from 'react-global-configuration';
import AuthStore from "../../stores/AuthStore";



var PlanActions = {
  
  getList(token, accommodation_id){
    
    fetch(config.get("VRM2_PATH") + config.get("API_ACCOMMODATION")  + "/" +  accommodation_id + config.get("API_PLAN_LIST"), {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token
      }
     
    }).then(function(response) {

      if(response.status == 200){
        return response.json()
      }else if(response.status == 401){
        AuthActions.logout()

        return null
      }else{
        return null
      }
    }).then(function(data) {

      if(data != null ){

        AppDispatcher.dispatch({
          actionType: Constants.PLAN_LIST_LOADED,
          list: data.plans
        })
      }
    })
  },


  update(token,accommodation_id,plan_id, params){

    fetch(config.get("VRM2_PATH") + config.get("API_ACCOMMODATION") + "/" + accommodation_id + "/plans/" + plan_id , {
      method: "PATCH",
      headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        return null
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      if(data && !data.error){
        PlanActions.getList(token,accommodation_id)
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error,
          message:config.get("ERROR_API")
        })
      }
    }).catch(function(error) {
      AppDispatcher.dispatch({
        actionType: Constants.API_ERROR,
        message:config.get("ERROR_API")
      })
    })
  },



}

export default PlanActions
