import AppDispatcher from "../../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../../constants/Constants"
import assign from "object-assign"
import dayjs from "dayjs"

var CHANGE_EVENT = "update" // chenge evnetを定数にする


var _items = []
var _named_items = []
var _item = null

///BODY
var setList = (list) => {
  _items = list
}

var PlanStore = assign({},EventEmitter.prototype,{


  getList(){
    return _items
  },





  getById(id){
    for(var i in _items){
      if(_items[i].id == id )return _items[i]
    }
    return null
  },

  getByDate(date){
    var available = []

    for(var i in _items) {
      const item = _items[i]
      if(dayjs(date).diff(dayjs(item.start_at), "day") >= 0  && dayjs(date).diff(dayjs(item.end_at), "day") <= 0  ){
        available.push(item)
      }
    }

    return available
  },

  ///EMIT/ADD/REMOVE
  emitChange(eventname = CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(eventname)
  },

  addChangeListener(callback, eventname = CHANGE_EVENT){ // リスナーに追加
    this.on(eventname, callback)
  },

  removeChangeListener(callback,eventname = CHANGE_EVENT){ // リスナーから削除
    this.removeListener(eventname, callback)
  }

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.PLAN_LIST_LOADED:
     
      setList(action.list);
      PlanStore.emitChange()
    break;

    default:
      // noGET_DETAIL
  }
})
export default PlanStore
