import AppDispatcher from "../../dispatcher/AppDispatcher"
import Constants from "../../constants/Constants"
import 'whatwg-fetch'
import AuthActions from '../AuthActions'
import config from 'react-global-configuration';
import cookie from 'react-cookies'


var MenuActions = {

  getList(token){

    fetch(config.get("API_SHOP_CATALOG_PATH") + config.get("API_SHOP_CATALOG")  , {
      method: 'GET',
      headers: {
       'Authorization': 'Bearer ' + token
      }
    }).then(function(response) {

      if(response.status == 200){
        return response.json()
      }else if(response.status ===401){
        AuthActions.logout()
        return null
      }else{
        return null
      }
    }).then(function(data) {

      if(data){
        AppDispatcher.dispatch({
          actionType: Constants.MENU_LIST_LOADED,
          list: data.products,
        })

      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          message: "エラーが発生しました"
        })

      }

    });
  },

  update(token, item){
    fetch(config.get("API_SHOP_CATALOG_PATH") + config.get("API_SHOP_CATALOG") + "/" + item.id , {
      method: 'PATCH',
      headers: {
       'Authorization': 'Bearer ' + token,
       'Content-Type': 'application/json'
     },
     body: JSON.stringify({
       price:item.price,
       title:item.title,
       sub_title:item.sub_title,
       status:Number(item.status),
       category:item.category ? Number(item.category.id) : null,
       payment_method:item.payment_method.split(",").map(id => {return Number(id)}),
       delivery_method:item.delivery_method.split(",").map(id => {return Number(id)})
     })
    }).then(function(response) {

      if(response.status == 200){
        return response.json()
      }else if(response.status ===401){
        AuthActions.logout()
        return null
      }else{
        return null
      }
    }).then(function(data) {
      if(data){

        AppDispatcher.dispatch({
          actionType: Constants.MENU_UPDATE,
          item: data
        })


      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          message: "エラーが発生しました"
        })

      }

    });
  },
  updateItem(item){
    AppDispatcher.dispatch({
      actionType: Constants.MENU_UPDATE,
      item: item
    })
  }
}

export default MenuActions
