import React from "react"
import config from 'react-global-configuration';
import Common from '../../../utiles/Common'
import styles from '../../../styles/site.css'


/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
},
*/

export default class Menu extends React.Component {


  constructor(){
    super()
    this.clickCalendar = this.clickCalendar.bind(this)

    this.state = {

    }
  }

  componentDidMount(){

  }

  componentWillUnmount(){

  }

  clickCalendar(e){

  }



  render(){
    //BBQ
    const bbq_list = this.props.menu.map((item,i) => {

      //plans
      /*const plan_html = this.getSiteInfoFormPlan(id).map((item) => {
        return <div className="input-box"><input type="radio" name="site" value={item.room.id} onChange={()=>{this.props.changeHandler(id, item.room.id, item.id)}} />{item.name}</div>
      })*/

      if(item.category.id == config.get("CATEGORY_BBQ")){

        return(<div className="site-box">
            <div className="input-box menu_select">
              <input type="radio" name="bbq" id={"menu_" + item.id} value={this.props.selected_food} onChange={()=>{this.props.bbqChangeHandler(item.id)}} />
              <label className="radio-parts" htmlFor={"menu_" + item.id}>{item.title}(税別)</label>
            </div>

          </div>
        )
      }

    })


    return(
      <section className="order">
        <h2>BBQメニュ−選択</h2>
        <div bbq_list="site-select">
          {bbq_list}
        </div>


      </section>
    )
  }
}
