import React,{useState,useEffect,useCallback} from 'react'
import PlanActions from '../actions/farm/PlanActions'
import AuthActions from '../actions/AuthActions';
import PlanStore from '../stores/farm/PlanStore';
import Menu from './parts/farm/Menu'
import Header from './parts/Header'
import {format,addDays,isSameDay,getDay,getMonth, getYear,getDate,subDays} from 'date-fns'
import config from 'react-global-configuration';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AuthStore from '../stores/AuthStore';


function PlanListApp(props:PropsValue) {


  const [list, setList] = useState(null)

  const stablePlanChangeHandler = useCallback(planChangeHandler, [])



  useEffect(() => {
    
    PlanStore.addChangeListener(stablePlanChangeHandler)
    AuthActions.setScopeAuth("farm",PlanActions.getList, config.get("ACCOMMODATION_ID_BBQ") )
    //PlanActions.getList(AuthStore.getToken(),accommodation_id, )
    return function cleanup() {
      PlanStore.removeChangeListener(stablePlanChangeHandler)
    
    };

  },[stablePlanChangeHandler]);


  function setStartDate(type, id, value){
   const token = AuthStore.getScopeToken("farm")

   var item = null
   if(type === "start_at"){
      item = {start_at:format(value, "yyyy-MM-dd")}
    }else  if(type === "end_at"){
      item = {end_at:format(value, "yyyy-MM-dd")}
    }
    PlanActions.update(token,config.get("ACCOMMODATION_ID_BBQ"),id,item)
  
  }


  function planChangeHandler(){

    setList(PlanStore.getList().map( item => {

      return <tr className="mail-list-item" key={"plan_list_" + item.id}>
          <td className="name">{item.name}</td>
          <td className="date"> <DatePicker dateFormat="yyyy/MM/dd" selected={new Date(item.start_at)} onChange={(date) => setStartDate("start_at", item.id, date)} /> </td>
          <td className="date"><DatePicker dateFormat="yyyy/MM/dd" selected={new Date(item.end_at)} onChange={(date) => setStartDate("end_at", item.id, date)} /> </td>
     

        </tr>
    }))
  }

  return(
      <div>
        <Header />
          <main>
            <div className="innner">
              <div className="main-header">
                <h1>The House Farm Plan</h1>
                <div className="right-menu">
                
                </div>
              </div>


              <table className="calendar list">
                <thead></thead>
                <tr><th>名称</th><th>開始</th><th>終了</th></tr>
                  {list}
              </table>
            </div>
          </main>
      </div>
    )
  
}

export default PlanListApp
