import React from "react"
import config from 'react-global-configuration';

import Input from '../Input'

import styles from '../../../styles/site.css'
import dayjs from "dayjs"
import SiteItem from './SiteItem'

/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
},
*/

export default class Site extends React.Component {


  constructor(){
    super()
    this.clickCalendar = this.clickCalendar.bind(this)
    this.sites = ["A","B","C", "D","E"]

    this.state = {
      year:null,
      month:null
    }
  }

  componentDidMount(){

    this.setState({
      items:[]
    })
  }

  componentWillUnmount(){

  }

  clickCalendar(e){

  }

  getSiteInfoFormPlan(id,date){

    var plans = []
    for(var i in this.props.plans){
      const item = this.props.plans[i]

      if(item.room.name == id && dayjs(date).diff(dayjs(item.start_at), "day") >= 0  && dayjs(date).diff(dayjs(item.end_at), "day") <= 0  ) {
        plans.push(item)
      }
    }

    return plans
  }

  render(){

    if(!this.props.date) return null
    //selected

    var site = null
    const plan_list = this.sites.map((id,i) => {

      //plans
      const plan_html = this.getSiteInfoFormPlan(id,this.props.date).map((item) => {
        var has_book = false

        if(this.props.booked) {
          for(var i in this.props.booked.booked_detail){
            const book = this.props.booked.booked_detail[i]

            if(book.room_id == item.room.id && book.plan_id == item.id){
              has_book = true
            }
          }
        }

        //console.log(has_book)

         var checked=""
         for(var i in this.props.selected){
           const selected_item = this.props.selected[i]
           if(selected_item.room_id == item.room.id && selected_item.plan_id == item.id){
             checked = "checked"
           }
         }




        const divclass = has_book ? "not-available" : ""
        const input = has_book ? <span>{item.name}</span> :
          <SiteItem item={item} divclass={divclass} changeHandler={this.props.changeHandler}  checked={checked} />

        return <div className={"input-box " + divclass}>{input}</div>
      })


      return(
        <div className="site-box" key={"itt_" + id}>
          <div className="site-content"><h3>ブース{id}: {config.get("SITE_TEXT_" + id)}</h3>{plan_html}</div>
        </div>
      )
    })


    return(

        <section className="order">
          <h2>キッチンブース選択</h2>

          <div className="site-select">
              {plan_list}
          </div>
        </section>


    )
  }
}
