import React, { useState, useEffect,useCallback } from 'react'
import {  Link } from 'react-router-dom'
import config from 'react-global-configuration';
import {format} from 'date-fns'
import Switch from "react-switch";

import AuthActions from '../actions/AuthActions'
import AuthStore from '../stores/AuthStore'

import MenuActions from '../actions/scrasy/MenuActions'
import MenuStore from '../stores/scrasy/MenuStore'

import ErrorStore from '../stores/ErrorStore'
import Header from './parts/Header'
import MediaList from './parts/takeaway/MediaList'
import styles from "../styles/takeaway.css"


/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/


function MenuApp(props:PropsValue) {

  //const { accommodation_id } = useParams();

  const [list, setList] = useState(null)
  const [media_list, setMediaList] = useState(null)


  const stableMenuChangeHandler = useCallback(menuChangeHandler, [])
  function menuChangeHandler(){
    setMediaList(null)
    setList(
      MenuStore.getList().map(
        item => {
          return createListItem(item)
        }
      )
    )
  }

  const stableMediaChangeHandler = useCallback(mediaChangeHandler,[])

  function mediaChangeHandler(){

    setMediaList(null)
  }




  useEffect(() => {
    MenuStore.addChangeListener(stableMenuChangeHandler)
    MenuStore.addChangeListener(stableMediaChangeHandler,"updated")
    AuthActions.setAuth("takeaway")
    AuthActions.setScopeAuth("takeaway",MenuActions.getList)

    return function cleanup() {
      MenuStore.removeChangeListener(stableMenuChangeHandler)
      MenuStore.removeChangeListener(stableMediaChangeHandler,"updated")
    };

  },[stableMenuChangeHandler,stableMediaChangeHandler]);



  function update(e,id){
    const target = e.target
    var item = MenuStore.getItemFromList(id)
    if(target.name === "price"){
      item = item.set(target.name,Number(target.value))
    }else{
      item = item.set(target.name,target.value)
    }


    MenuActions.updateItem(item)
  }

  function onBlur(id){
    const item = MenuStore.getItemFromList(id)
    MenuActions.update(AuthStore.getScopeToken("takeaway"), item)
  }

  function statushandleChange(id, checked){
    var item = MenuStore.getItemFromList(id)
    item = item.set("status",checked === true ? 1 : 0)

    MenuActions.updateItem(item)
    MenuActions.update(AuthStore.getScopeToken("takeaway"), item)
  }

  function setMedia(item){
    setMediaList(<MediaList item={item} closeHandler={clearMedia}/>)
  }

  function clearMedia(){
    setMediaList(null)
  }

  function createListItem(item){
    if(!item) return null
    //const address_item = item.address.length > 0
    return (<tr key={"list_item_" + item.id}>
        <td className="image" onClick={() => setMedia(item)}>{item.medias.length > 0 ? <img src={item.medias[0].url} alt="product"/> : null} </td>
        <td><input type="text" value={item.title} name="title" onChange={(e) => update(e, item.id)} onBlur={() => onBlur(item.id)}/></td>
        <td><input type="text" value={item.sub_title} name="sub_title" onChange={(e) => update(e, item.id)} onBlur={() => onBlur(item.id)}/></td>
        <td classNane="price"><input type="text" value={item.price} className="sml" name="price" onChange={(e) => update(e, item.id)} onBlur={() => onBlur(item.id)}/></td>
        <td><Switch onColor="#e4007c" onChange={(checked) => statushandleChange(item.id,checked)} checked={item.status === 1 ? true : false} /></td>
      </tr>

    )
  }

  function goto(url){
    window.location.href =url
  }

  return(
    <div>
    {media_list}
    <Header />
      <main>
        <div className="innner full">
          <div className="main-header">
            <h1>ドライブスルー管理</h1>
            <div className="right-menu">
              <div className="inline-link" onClick={() => goto("/takeaway")}><i className="fas fa-arrow-right"></i> 準備中の注文</div>

            </div>
          </div>
          <h2>メニュー一覧</h2>
          <table className="list product">
            <tr className="s1">
              <th>写真</th>
              <th>メニュー名</th>
              <th>説明文</th>
              <th>値段</th>
              <th>掲載</th>
            </tr>
            {list}
          </table>


        </div>
      </main>
    </div>
  )
}

export default MenuApp
