
import 'whatwg-fetch'


var SlackActions = {

  sendMessage(message){
    console.log(message)
    const url='https://hooks.slack.com/services/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'
    const json = {
      text:"NOTICE: " + message
    };

    var data = new FormData()
    data.append('payload', JSON.stringify(json))

    fetch(url , {
        method: 'POST',
        body: data
    })

  }



}

export default SlackActions
