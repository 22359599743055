import React, { useState, useEffect,useCallback } from 'react'
import {  Link } from 'react-router-dom'
import config from 'react-global-configuration';
import {format,parseISO,differenceInMinutes} from 'date-fns'

import AuthActions from '../actions/AuthActions'
import AuthStore from '../stores/AuthStore'

import OrderActions from '../actions/scrasy/OrderActions'
import OrderStore from '../stores/scrasy/OrderStore'

import AccommodationActions from '../actions/scrasy/AccommodationActions'
import AccommodationStore from '../stores/scrasy/AccommodationStore'


import ErrorStore from '../stores/ErrorStore'
import Header from './parts/Header'
import SMS from './parts/takeaway/SMS'
import Status from './parts/takeaway/Status'
import AlertBox from './parts/AlertBox'
import styles from "../styles/takeaway.css"
import ReactTimeout from 'react-timeout'



/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/


function TakeAwayApp(props:PropsValue) {

  //const { accommodation_id } = useParams();

  const [list, setList] = useState(null)
  const [list_s, setSList] = useState(null)
  const [list_p, setPList] = useState(null)
  const [updated_at, setUpdatedAt] = useState(null)
  const [sms, setSms] = useState(null)
  const [status,setStatus] = useState(null)
  const [statusMessage, setStatusMessage] = useState(null)
  const [alertBox, setAlertBox] = useState(null)
  const [is_updating, setUpdating] = useState(true)
  var tinmer = null
  const stableAccommdationChangeHandler = useCallback(accommdationChangeHandler,[])
  const stableOrderChangeHandler = useCallback(orderChangeHandler, [])
  function orderChangeHandler(){
    setList(
      OrderStore.getNamedList(1).map(
        item => {
          return createListItem(item)
        }
      )
    )
    setSList(
      OrderStore.getNamedList(2).map(
        item => {
          return createListItem(item)
        }
      )
    )
    setPList(
      OrderStore.getNamedList(3).map(
        item => {
          return createListItem(item)
        }
      )
    )
    setUpdatedAt(format((new Date()), "yyyy年M月d日 H時mm分 ss秒"))
    if(OrderStore.getNamedList(1).length > 0 ){
      setAlertBox(<AlertBox text="未確認の注文があります" okHandler={closeAlert} ok="OK"/>)
      const audio = new Audio("/sound/alert.mp3")
      audio.load()
      playAudio(audio)
    }else{
      setAlertBox(null)
    }
  }

  function reload(){
    OrderActions.getList(AuthStore.getScopeToken("takeaway"),1)
    OrderActions.getList(AuthStore.getScopeToken("takeaway"),2)
    OrderActions.getList(AuthStore.getScopeToken("takeaway"),3)
  }


  useEffect(() => {
    OrderStore.addChangeListener(stableOrderChangeHandler)
    AccommodationStore.addChangeListener(stableAccommdationChangeHandler)
    AccommodationActions.getDetail()

    AuthActions.setScopeAuth("takeaway",OrderActions.getList,1)
    AuthActions.setScopeAuth("takeaway",OrderActions.getList,2)
    AuthActions.setScopeAuth("takeaway",OrderActions.getList,3)
    AuthActions.setScopeAuth("vrm")
    setInterval(reload, 61000)
    return function cleanup() {
      OrderStore.removeChangeListener(stableOrderChangeHandler)
      AccommodationStore.removeChangeListener(stableAccommdationChangeHandler)
      clearInterval(reload)
    };

  },[stableOrderChangeHandler,stableAccommdationChangeHandler]);

  function playAudio(audio) {
    const audioPromise = audio.play()
    if (audioPromise !== undefined) {
      audioPromise
        .then(_ => {
          // autoplay started
        })
        .catch(err => {
          // catch dom exception
          console.info(err)
        })
    }
  }

  function accommdationChangeHandler(){
    var item = AccommodationStore.getItem()
    if(item){
      setStatusMessage(
        item.description
      )
    }

  }
  function update(item,num){

    if(is_updating){

      setUpdating(false)
      OrderActions.update(AuthStore.getScopeToken("takeaway"),item , num)
    }else{
      console.log("error")
    }

  }

  function deleteItem(item){
    OrderActions.update(AuthStore.getScopeToken("takeaway"),item , null, false, 20)
  }

  function closeSms(){
    setSms(null)
  }

  function sendSms(item){
    setSms(<SMS item={item} closeHandler={closeSms} />)
  }




  function createListItem(item){
    setUpdating(true)
    const name = item.customer_info == "" ? null : JSON.parse(item.customer_info).name
    const products = item.products.map(
      p => {
        if(p.quantity === 0){
          return null
        }else{
          return <tr><td>{p.product.title}</td><td className="right">{p.quantity}個</td></tr>
        }

      }
    )

    return (<tbody>
      <tr key={"list_item_" + item.id} className={item.payments.length > 0 ? item.comment.indexOf("デリバリー希望") >= 0 && item.shipping_info.length > 0 ? "delivery" : null : "unpaid"} >
          <td>{item.unique_id}</td>
          <td>{name}</td>
          <td><table className="inner">{products}</table></td>
          <td>{item.comment}</td>
          <td className="right">{item.total_price ? item.total_price.toLocaleString() : "-"}円</td>
          <td>{item.payments.length > 0 ? "済" : "未"}</td>
          <td className="right">{format(parseISO(item.created_at),"M/d HH時m分")} <br/> {differenceInMinutes(new Date(), parseISO(item.created_at))}分経過</td>
          <td>{config.get("TAKEAWAY_STATUS_" + item.status)}</td>
          <td className="center"><div className="sms-button" onClick={() => sendSms(item)}><i className="far fa-envelope"></i></div></td>
          <td className="check" >
          {item.status === 1 ? <div onClick={() => update(item , 2)} ><i className="far fa-check-circle"></i> 確認</div>  : null  }
          {item.status === 2 || item.status === 3  ? <div onClick={() => update(item , Number(item.status) + 1)} ><i className="far fa-check-circle"></i> 完了</div> : null  }
          {item.status === 3 ? <div onClick={() => update(item , 2)} ><i className="far fa-check-circle"></i> 戻す</div> : null }
          <div onClick={() => deleteItem(item)} >キャンセル</div>
          </td>
        </tr>
        {item.comment.indexOf("デリバリー希望") >= 0 && item.shipping_info.length > 0 ? <tr className="delivery"><td></td><td colspan="9" className="sml">{JSON.parse(item.shipping_info).address}</td></tr> : null}
      </tbody>)
  }

  function goto(url){
    window.location.href = url
  }
  function changeMessage(){
    setStatus(<Status  closeHandler={closeStatus} />)
  }
  function closeStatus(){
    setStatus(null)
  }
  function closeAlert(){
    setAlertBox(null)
  }
  return(
    <div>
    {sms}
    {status}
    {alertBox}
    <Header />

      <main>
        <div className="innner full">
          <div className="main-header">
            <h1>ドライブスルー管理 [{updated_at}更新]</h1>
            <div className="right-menu">
              <div className="inline-link" onClick={() => goto("/takeaway/all")}><i className="fas fa-arrow-right"></i> 全ての注文</div>
              <div className="inline-link" onClick={() => goto("/takeaway/menu")}><i className="fas fa-arrow-right"></i> メニュー編集</div>
            </div>
          </div>
          <div className="status-message">{statusMessage && statusMessage != "" ? statusMessage : "(表示なし)"}</div>
          <div className="status-message-button" onClick={changeMessage}>[変更]</div>

          <h2>新規受け付け</h2>
          <table className="list">
            <tr className="s1">
              <th>受付番号</th>
              <th>連絡先</th>
              <th>注文内容</th>
              <th>予約時間</th>
              <th>支払金額</th>
              <th>支払</th>
              <th>受付時間</th>
              <th>ステータス</th>
              <th>SMS</th>
              <th>確認</th>
            </tr>
            {list}
          </table>


          <h2>調理中注文</h2>
          <table className="list">
            <tr className="s2">
              <th>受付番号</th>
              <th>連絡先</th>
              <th>注文内容</th>
              <th>予約時間</th>
              <th>支払金額</th>
              <th>支払</th>
              <th>受付時間</th>
              <th>ステータス</th>
              <th>SMS</th>
              <th>更新</th>
            </tr>
            {list_s}
          </table>

          <h2>準備完了注文</h2>
          <table className="list">
            <tr className="s3">
              <th>受付番号</th>
              <th>連絡先</th>
              <th>注文内容</th>
              <th>予約時間</th>
              <th>支払金額</th>
              <th>支払</th>
              <th>受付時間</th>
              <th>ステータス</th>
              <th>SMS</th>
              <th>更新</th>
            </tr>
            {list_p}
          </table>

        </div>
      </main>
    </div>
  )
}

export default TakeAwayApp
