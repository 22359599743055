import keyMirror from "keymirror"

export default keyMirror({
  ///Messagections
  MESSAGE_CREATE:null,

  ///AUTH
  AUTH: null,
  AUTH_LOGOUT:null,
  AUTH_ERROR:null,
  AUTH_NOUSER:null,
  AUTH_SCOPE:null,

  API_BOOKED_LIST_LOADED:null,
  API_BOOKED_DETAIL_LOADED:null,
  PLAN_LIST_LOADED:null,
  MENU_LIST_LOADED:null,
  MENU_UPDATE:null,

  BOOK_CREATE_NEW:null,
  BOOK_UPDATE:null,
  BOOK_CREATED:null,
  BOOK_DELETED:null,

  SNS_SENT:null,
  SNS_UPDATE:null,
  PRODUCT_LIST_LOADED:null,
  ACCOMMODATION_LOADED:null,
  ACCOMMODATION_STATUS_UPDATE:null,

  ORDER_LIST_LOADED:null,

  VRM_BOOK_LIST_LOADED:null,
  VRM_BOOK_OPT_LIST_LOADED:null,
  

  FILE_LIST_LOADED:null,
  FILE_LIST_UPDATED:null,
  API_ERROR:null
})
