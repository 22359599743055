const config_default = {

    HP:'',
    API_AUTH:                   '/oauth/auth',
    AUTH_farm:                  '/oauth/auth',
    AUTH_takeaway:              '/oauth/auth',
    AUTH_vrm:                   '/oauth/auth',
    API_ACCOUNT:                '/accounts',
    API_PROJECT:                '/projects',

    API_BOOK:                   '/book',
    API_BOOKS:                  '/books',
    API_BOOKED_UPDATE_MEMO:     '/book/update/memo',
    API_BOOK_CREATE:            '/book/update',
    API_BOOK_DELETE:            '/book/delete',
    API_PLAN_LIST :             "/plans",
    API_BOOK_LIST :             "/books",
    API_CALENDAR :              "/admin/books",

    API_PRODUCTS_LIST :         "/products",
    API_CART_ORDER:             "/shop/trades",
    API_SHOP_CATALOG:           "/shop/products",
    API_FILE:                   "/shop/files",
    API_PRODUCT_MEDIA:           "/shop/products/media",
    API_VRM_AUTH:               '/oauth/auth',
    API_PAYMENT_CREATE:         '/user/payment/create',

    API_SMS:                    '/sms',
    API_ACCOMMODATION:         '/admin/accommodations',
    API_ACCOMMODATION_USER:    '/accommodations',

    API_VRM_BOOKS:             '/admin/books',

    WEEK_0:   '日',
    WEEK_1:   '月',
    WEEK_2:   '火',
    WEEK_3:   '水',
    WEEK_4:   '木',
    WEEK_5:   '金',
    WEEK_6:   '土',

    TAX_RATE: 0.08,
    CLOSED_DAY:4,

    NUMBER_A:[6,12],
    NUMBER_B:[4,6],
    NUMBER_C:[6,12],
    NUMBER_D:[6,12],
    NUMBER_E:[4,6],

    MESSAGE_UPDATED:"更新しました",
    PAYMENT_0:"カード支払い",
    PAYMENT_1:"請求書払い",
    TAKEAWAY_STATUS_1:"新規受付",
    TAKEAWAY_STATUS_2:"調理中",
    TAKEAWAY_STATUS_3:"準備完了",
    TAKEAWAY_STATUS_4:"受け渡し完了",
    TAKEAWAY_STATUS_20:"キャンセル",

    PRODUCT_STATUS_1:"掲載",
    PRODUCT_STATUS_0:"非掲載",

    API2_KEY:                     'eeV8ohnggeeNg6va',
    API2_ID:                      '1b6453892473a467d07372d45eb05abc2031647a',
    SMS_CREATED :"お客様のオーダーのご準備完了です。\nTHE HOUSE FARMへお越しの上、『IN』から入り、黄色い小屋で注文ナンバーの紙袋をpickupして終了です。\n\n1日も早く、皆様が笑顔あふれる日常が戻りますように。\n電話番号：070-3197-1831",
    SMS_CLIENT:"TheHouse",
    ACCOMMODATION_ID:22,

    PLAN_D:45,
    PLAN_L:47,
    ROOM_D:[42,43,44,45,46],
    ROOM_L:[25,26,27,28,29],
    ROOM_MAX:5,
    COMPANY_TH_ID:1,
      
}

const config_dev = {
  API_AUTH_PATH: 'http://crewauth.cowzow.jp',
  CREW_PATH: 'http://crewapi.cowzow.jp',
  VRM2_PATH: 'http://localhost:9000',
  PATH_farm: 'http://localhost:9120',
  
  PATH_takeaway: 'https://auth.scrasy.jp',
  PATH_vrm: 'http://localhost:9120',
  API_SHOP_CATALOG_PATH: 'https://catalog.scrasy.jp',
  API_SHOP_CART_PATH: 'https://cart.scrasy.jp',
  FARM_BBQ:15,
  FARM_RESTRANT:21,
  CATEGORY_BBQ:18,
  CATEGORY_DRINK:19,
  SHOP_KEY:"thehousefarm_ae4ohyaH",
  LOGIN_URL:'/',
  COMPANY_ID:4,
  ACCOMMODATION_ID_BBQ:15,
  BBQ_M:42,
  BBQ_A:43,
  /** ROOM_A:25,
  ROOM_B:26,
  ROOM_C:27,
  ROOM_D:28,
  ROOM_E:29, **/ 
  PLANS:[
    {accommodation_id:1, name:"TH:", plan_id:91}, 
    {accommodation_id:2, name:"SH:", plan_id:92}, 
    {accommodation_id:23, name:"PAL:", plan_id:93},
    {accommodation_id:24, name:"MH:", plan_id:69},
    {accommodation_id:24, name:"MH:", plan_id:86},
    {accommodation_id:24, name:"MH:", plan_id:88},
    {accommodation_id:26, name:"MVH:", plan_id:123},
    {accommodation_id:26, name:"MVH:", plan_id:124},
    {accommodation_id:27, name:"MVJ:", plan_id:128},
    {accommodation_id:27, name:"MVJ:", plan_id:129}],
    
  OPTIONS:[
    {accommodation_id:1, name:"TH:", option_id:16}, 
    {accommodation_id:1, name:"TH:", option_id:67},
    {accommodation_id:1, name:"TH:", option_id:75},
    {accommodation_id:2, name:"SH:", option_id:76},
    {accommodation_id:2, name:"SH:", option_id:27},
    {accommodation_id:23, name:"小網:", option_id:37},
    {accommodation_id:23, name:"小網:", option_id:74},
    {accommodation_id:23, name:"小網:", option_id:77},
    {accommodation_id:23, name:"小網:", option_id:37},
    {accommodation_id:24, name:"諸磯:", option_id:44},
    {accommodation_id:24, name:"諸磯:", option_id:73},
    {accommodation_id:24, name:"諸磯:", option_id:78}]
};

const config_production = {
  API_SHOP_CATALOG_PATH: 'https://catalog.scrasy.jp',
  API_AUTH_PATH: 'http://crewauth.cowzow.jp',
  CREW_PATH: 'http://crewapi.cowzow.jp',
  API_SHOP_CART_PATH: 'https://cart.scrasy.jp',
  VRM_PATH: 'https://vrmapi.cowzow.jp',
  VRM2_PATH: 'https://api.vrm.ninja',
  PATH_farm: 'https://vrmauth.cowzow.jp',
  PATH_takeaway: 'https://auth.scrasy.jp',
  PATH_vrm: 'https://vrmauth.cowzow.jp',
  FARM_BBQ:15,
  FARM_RESTRANT:21,
  SHOP_KEY:"thehousefarm_ae4ohyaH",
  CATEGORY_BBQ:18,
  CATEGORY_DRINK:19,
  LOGIN_URL:'/',
  COMPANY_ID:4,
  ACCOMMODATION_ID_BBQ:15,
  BBQ_M:45,
  BBQ_A:46,
  /** ROOM_A:25,
  ROOM_B:26,
  ROOM_C:27,
  ROOM_D:28,
  ROOM_E:29, **/
  PLANS:[
    {accommodation_id:1, name:"TH:", plan_id:91}, 
    {accommodation_id:1, name:"TH:", plan_id:101},
    {accommodation_id:1, name:"TH:", plan_id:103}, 
    {accommodation_id:1, name:"TH:", plan_id:104},
    {accommodation_id:1, name:"TH:", plan_id:118},
    {accommodation_id:2, name:"SH:", plan_id:92}, 
    {accommodation_id:2, name:"SH:", plan_id:114}, 
    {accommodation_id:2, name:"SH:", plan_id:105}, 
    {accommodation_id:2, name:"SH:", plan_id:106}, 
    {accommodation_id:2, name:"SH:", plan_id:119}, 
    {accommodation_id:2, name:"SH:", plan_id:120}, 
    {accommodation_id:23, name:"PAL:", plan_id:93},
    {accommodation_id:23, name:"PAL:", plan_id:96},
    {accommodation_id:23, name:"PAL:", plan_id:97},
    {accommodation_id:23, name:"PAL:", plan_id:98},
    {accommodation_id:23, name:"PAL:", plan_id:99},
    {accommodation_id:23, name:"PAL:", plan_id:100},
    {accommodation_id:23, name:"PAL:", plan_id:108},
    {accommodation_id:23, name:"PAL:", plan_id:109},
    {accommodation_id:23, name:"PAL:", plan_id:110},
    {accommodation_id:23, name:"PAL:", plan_id:111},
    {accommodation_id:23, name:"PAL:", plan_id:112},
    {accommodation_id:23, name:"PAL:", plan_id:113},
    {accommodation_id:23, name:"PAL:", plan_id:121},
    {accommodation_id:24, name:"MH:", plan_id:69},
    {accommodation_id:24, name:"MH:", plan_id:86},
    {accommodation_id:24, name:"MH:", plan_id:88},
    {accommodation_id:24, name:"MH:", plan_id:102},
    {accommodation_id:24, name:"MH:", plan_id:107},
    {accommodation_id:26, name:"MVH:", plan_id:123},
    {accommodation_id:26, name:"MVH:", plan_id:124},
    {accommodation_id:27, name:"MVJ:", plan_id:128},
    {accommodation_id:27, name:"MVJ:", plan_id:129}
    
  ],
  OPTIONS:[
    {accommodation_id:1, name:"TH:", option_id:16}, 
    {accommodation_id:1, name:"TH:", option_id:75}, 
    {accommodation_id:1, name:"TH:", option_id:108}, 
    {accommodation_id:1, name:"TH:", option_id:146}, 
    {accommodation_id:1, name:"TH:", option_id:150}, 
    {accommodation_id:1, name:"TH:", option_id:172}, 

    {accommodation_id:2, name:"SH:", option_id:27},
    {accommodation_id:2, name:"SH:", option_id:76},
    {accommodation_id:2, name:"SH:", option_id:92}, 
    {accommodation_id:2, name:"SH:", option_id:109},
    {accommodation_id:2, name:"SH:", option_id:147},
    {accommodation_id:2, name:"SH:", option_id:151},
    {accommodation_id:2, name:"SH:", option_id:173},

    {accommodation_id:14, name:"CH:", option_id:169},

    {accommodation_id:23, name:"小網:", option_id:37},
    {accommodation_id:23, name:"小網:", option_id:74},
    {accommodation_id:23, name:"小網:", option_id:77},
    {accommodation_id:23, name:"小網:", option_id:103},
    {accommodation_id:23, name:"小網:", option_id:104},
    {accommodation_id:23, name:"小網:", option_id:105},
    {accommodation_id:23, name:"小網:", option_id:106},
    {accommodation_id:23, name:"小網:", option_id:107},
    {accommodation_id:23, name:"小網:", option_id:148},
    {accommodation_id:23, name:"小網:", option_id:152},
    {accommodation_id:23, name:"小網:", option_id:174},
    {accommodation_id:26, name:"小網:", option_id:198},

    {accommodation_id:24, name:"諸磯:", option_id:110},
    {accommodation_id:24, name:"諸磯:", option_id:44},
    {accommodation_id:24, name:"諸磯:", option_id:73},
    {accommodation_id:24, name:"諸磯:", option_id:78},
    {accommodation_id:24, name:"諸磯:", option_id:149},
    {accommodation_id:24, name:"諸磯:", option_id:153},
    {accommodation_id:24, name:"諸磯:", option_id:175},
    {accommodation_id:24, name:"諸磯:", option_id:176},

    {accommodation_id:26, name:"MVH:", option_id:184},
    {accommodation_id:26, name:"MVH:", option_id:196},

    {accommodation_id:27, name:"MVJ:", option_id:200},
    {accommodation_id:27, name:"MVJ:", option_id:210},
    {accommodation_id:27, name:"MVJ:", option_id:213}


]
};


var config = {}

if(process.env.NODE_ENV === "development"){
  config = Object.assign(config_default,config_dev)
}else if(process.env.NODE_ENV === "production"){
  config = Object.assign(config_default,config_production)
}

export default config;
