import AppDispatcher from "../../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../../constants/Constants"
import assign from "object-assign"
import Product from "../../vo/Product"


var CHANGE_EVENT = "change" // chenge evnetを定数にする
const UPDATED = "update"

var _items = []

var setList = (list) => {
  _items = list
}


var MediaStore = assign({},EventEmitter.prototype,{

  getList(){
    return _items
  },



  emitChange(event_type = CHANGE_EVENT ){ // 何かアクションがあったことを知らせる
    this.emit(event_type)
  },

  addChangeListener(callback, event_type = CHANGE_EVENT ){ // リスナーに追加
    this.on(event_type, callback)
  },

  removeChangeListener(callback, event_type = CHANGE_EVENT ){ // リスナーから削除
    this.removeListener(event_type, callback)
  }

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.FILE_LIST_LOADED:
      setList(action.list);
      MediaStore.emitChange()
      break;
  

    default:
      // no
  }
})
export default MediaStore
