import React, { useState, useEffect,useCallback } from 'react'
import {  Link } from 'react-router-dom'
import config from 'react-global-configuration';
import {format,parseISO} from 'date-fns'

import AuthActions from '../actions/AuthActions'
import AuthStore from '../stores/AuthStore'

import OrderActions from '../actions/scrasy/OrderActions'
import OrderStore from '../stores/scrasy/OrderStore'

import ErrorStore from '../stores/ErrorStore'
import Header from './parts/Header'
import styles from "../styles/takeaway.css"

/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/


function TakeAwayFullApp(props:PropsValue) {

  //const { accommodation_id } = useParams();

  const [list, setList] = useState(null)



  const stableOrderChangeHandler = useCallback(orderChangeHandler, [])
  function orderChangeHandler(){
    setList(
      OrderStore.getList().map(
        item => {
          return createListItem(item)
        }
      )
    )

  }




  useEffect(() => {
    OrderStore.addChangeListener(stableOrderChangeHandler)
    AuthActions.setAuth("takeaway")
    AuthActions.setScopeAuth("takeaway",OrderActions.getList)

    return function cleanup() {
      OrderStore.removeChangeListener(stableOrderChangeHandler)
    };

  },[stableOrderChangeHandler]);

  function update(item, num){
    OrderActions.update(AuthStore.getScopeToken("takeaway"),item, num, true)
  }

  function createListItem(item){
    const name = item.customer_info == "" ? null : JSON.parse(item.customer_info).name
    const products = item.products.map(
      p => {
        if(p.quantity === 0){
          return null
        }else{
          return <tr><td>{p.product.title}</td><td className="right">{p.quantity}個</td></tr>
        }
      }
    )
    return (<tr key={"list_item_" + item.id}>
        <td>{item.unique_id}</td>
        <td>{name}</td>
        <td><table className="inner">{products}</table></td>
        <td>{item.comment}</td>
        <td className="right">{item.total_price ? item.total_price.toLocaleString() : "-"}円</td>
        <td>{item.payments.length > 0 ? "済" : "未"}</td>
        <td>{format(parseISO(item.created_at),"M月d日 hh時mm分")}</td>

        <td>{config.get("TAKEAWAY_STATUS_" + item.status)}</td>
        <td className="check" >{item.status == 3 ? <div onClick={() => update(item , -1)} ><i className="far fa-check-circle"></i> 戻す</div> : null}</td>
      </tr>)
  }

  function goto(url){
    window.location.href =url
  }

  return(
    <div>
    <Header />
      <main>
        <div className="innner full">
          <div className="main-header">
            <h1>ドライブスルー管理</h1>
            <div className="right-menu">
              <div className="inline-link" onClick={() => goto("/takeaway")}><i className="fas fa-arrow-right"></i> 準備中注文</div>
              <div className="inline-link" onClick={() => goto("/takeaway/menu")}><i className="fas fa-arrow-right"></i> メニュー編集</div>
            </div>
          </div>

          <table className="list">
            <tr className="s1">
              <th>受付番号</th>
              <th>連絡先</th>
              <th>注文内容</th>
              <th>予約時間</th>
              <th>支払金額</th>
              <th>支払</th>
              <th>受付時間</th>
              <th>ステータス</th>
              <th>戻る</th>
            </tr>
            {list}
          </table>


        </div>
      </main>
    </div>
  )
}

export default TakeAwayFullApp
