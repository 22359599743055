import React from "react"
import {Link } from 'react-router-dom'
import AuthActions from '../../actions/AuthActions'

import config from 'react-global-configuration';

import logo from '../../images/logo.svg'
/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class Header extends React.Component {

  constructor(){
    super()
    this.state = {
    }
  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }

  logout(){
    AuthActions.logout()
  }

  render(){


    return(
      <header>
        <div className="innner">

          <div className="menu">
            <a href="/farm"><img src={logo} alt="logo" /></a>
          </div>

          <div className="mini-menu">
            <div><a href="/farm">ファーム予約</a></div>
            <div><a href="/farm/plan">ファーム設定</a></div>
            <div><a href="/takeaway">ドライブスルー</a></div>
            <div className="link" onClick={this.logout}>ログアウト</div>
          </div>
        </div>
      </header>

    )
  }
}
